/* eslint-disable @typescript-eslint/no-unused-vars */

import {connect} from 'react-redux';
import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import {withRouter} from 'react-router';
import {Form, Formik} from 'formik';
import {compose} from 'recompose';
import {object, array, string, number, mixed, ref} from 'yup';
import get from 'lodash/get';
import set from 'lodash/set';
import isNil from 'lodash/isNil';
import {Card} from '@shipwell/shipwell-ui';
import {
  CustomFieldEntityTypesEnum,
  ShipmentLineItem,
  CustomField,
  Shipment,
  Company,
  TotalWeightOverrideUnitEnum,
  BaseShipmentTotalDeclaredValueCurrencyEnum,
  ProductWeightUnitEnum,
  ProductLengthUnitEnum,
  ProductValuePerPieceCurrencyEnum,
  ProductTempUnitEnum,
  ShipmentLineItemDetail
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  createDefaultCustomDataValues,
  createDefaultLineItem
} from 'App/formComponents/formSections/LineItemFields/utils/createDefaultLineItem';
import {LineItemFields} from 'App/formComponents/formSections/LineItemFields';
import ShipmentItemTotals from 'App/formComponents/formSections/_shipmentItemTotals';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {emptyStringToNull, validateDecimalPoints} from 'App/utils/yupHelpers';
import {validateDollarValue} from 'App/utils/globals';
import {countTotalDigits} from 'App/utils/globalsTyped';
import {CustomFieldsContextProvider, CustomFieldsContextConsumer} from 'App/data-hooks';
import {isCustomFieldOwner} from 'App/utils/customData';
import {getCustomDataPath} from 'App/utils/customDataPath';
import withStatusToasts from 'App/components/withStatusToasts';
import omitEmptyKeys from 'App/utils/omitEmptyKeys';
import validateNMFCCode, {validateNMFCSubCode, nmfcCodeSchema, nmfcSubCodeSchema} from 'App/utils/validateNMFCCode';
import {State} from 'App/reducers/types';

export const checkHazmatRequired = (lineItems: Partial<ShipmentLineItem>[]) =>
  lineItems.map((lineItem) => {
    if (lineItem.hazmat_identification_number) {
      return {...lineItem, hazmat_required: true};
    }
    return lineItem;
  });

const createValidationSchema = (
  customFields: CustomField[] = [],
  isWeightRequired?: boolean,
  flagDecimalSupportForShipmentLineItems?: boolean,
  hasLTL?: boolean,
  hasVLTL?: boolean
) => {
  const packageWeightSchema = number()
    .nullable()
    .transform(emptyStringToNull)
    .typeError('Total weight must be a number.')
    .test({
      name: 'Total weight max 2 decimals',
      message: 'Enter up to two decimals.',
      test: (value) => (value ? validateDecimalPoints(value, 2) : true)
    });
  const maybeRequiredPackageWeightSchema = isWeightRequired
    ? packageWeightSchema.required('Package weight is required.')
    : packageWeightSchema;

  return object().shape({
    line_items: array().of(
      object().shape(
        {
          total_packages: number()
            .nullable()
            .typeError('Quantity must be a number.')
            .positive('Quantity must be a positive number.')
            .test('is-integer', 'Quantity must be a whole number.', function (value) {
              if (!flagDecimalSupportForShipmentLineItems) {
                return Number.isInteger(value);
              }
              return true;
            }),
          description: string().required('Description is required.'),
          package_weight: maybeRequiredPackageWeightSchema,
          freight_class: string()
            .nullable()
            .transform(emptyStringToNull)
            .typeError('Freight Class is Required.')
            .test({
              name: 'Freight Class Required for LTL',
              message: 'Freight Class is Required.',
              test: (value) => !!value || (!hasLTL && !hasVLTL)
            }),
          length: number()
            .nullable()
            .transform(emptyStringToNull)
            .typeError('Length must be a number.')
            .test({
              name: 'Length max 2 decimals',
              message: 'Enter up to two decimals.',
              test: (value) => (value ? validateDecimalPoints(value, 2) : true)
            }),
          total_pieces: number()
            .nullable()
            .transform(emptyStringToNull)
            .typeError('Number of Pieces must be a number.'),
          width: number()
            .nullable()
            .transform(emptyStringToNull)
            .typeError('Width must be a number.')
            .test({
              name: 'Width max 2 decimals',
              message: 'Enter up to two decimals.',
              test: (value) => (value ? validateDecimalPoints(value, 2) : true)
            }),
          height: number()
            .nullable()
            .transform(emptyStringToNull)
            .typeError('Height must be a number.')
            .test({
              name: 'Height max 2 decimals',
              message: 'Enter up to two decimals.',
              test: (value) => (value ? validateDecimalPoints(value, 2) : true)
            }),
          value_per_piece: mixed().test({
            name: 'valid dollar value',
            message: 'Enter a dollar value.',
            test: (value) => validateDollarValue(value)
          }),
          refrigeration_min_temp: number().when('refrigeration_required', {
            is: true,
            then: number()
              .transform(emptyStringToNull)
              .typeError('Min Temp must be a number.')
              .required('Min Temp is required.')
              .max(ref('refrigeration_max_temp'), 'Must not be more than Max Temp.'),
            otherwise: number()
              .transform(emptyStringToNull)
              .typeError('Min Temp must be a number.')
              .max(ref('refrigeration_max_temp'), 'Must not be more than Max Temp.')
              .nullable()
          }),
          refrigeration_max_temp: number().when('refrigeration_required', {
            is: true,
            then: number()
              .transform(emptyStringToNull)
              .typeError('Max Temp must be a number.')
              .required('Max Temp is required.')
              .min(ref('refrigeration_min_temp'), 'Must not be less than Min Temp.'),
            otherwise: number()
              .transform(emptyStringToNull)
              .typeError('Max Temp must be a number.')
              .min(ref('refrigeration_min_temp'), 'Must not be less than Min Temp.')
              .nullable()
          }),
          country_of_manufacture: string().nullable(),
          nmfc_item_code: nmfcCodeSchema,
          nmfc_sub_code: nmfcSubCodeSchema,
          custom_data: object()
            .nullable()
            .shape({
              shipwell_custom_data: object().shape(
                customFields.reduce(
                  (shape, cf) => ({
                    ...shape,
                    [cf.id as string]: cf.required ? string().required(`${cf.label} is required.`) : string().nullable()
                  }),
                  {}
                )
              )
            }),
          hazmat_identification_number: string()
            .nullable()
            .when(['hazmat_hazard_class', 'hazmat_packing_group', 'hazmat_proper_shipping_name'], {
              is: (hazmat_hazard_class: string, hazmat_packing_group: string, hazmat_proper_shipping_name: string) =>
                [hazmat_hazard_class, hazmat_packing_group, hazmat_proper_shipping_name].some((value) => !!value),
              then: string()
                .nullable()
                .required('Identification number is required.')
                .test(
                  'valid length',
                  'Ensure this field has no more than 16 characters.',
                  (value) => (value as string)?.length <= 16
                )
            }),
          hazmat_hazard_class: string()
            .nullable()
            .when(['hazmat_identification_number', 'hazmat_packing_group', 'hazmat_proper_shipping_name'], {
              is: (
                hazmat_identification_number: string,
                hazmat_packing_group: string,
                hazmat_proper_shipping_name: string
              ) =>
                [hazmat_identification_number, hazmat_packing_group, hazmat_proper_shipping_name].some(
                  (value) => !!value
                ),
              then: string()
                .nullable()
                .required('Hazard class is required.')
                .test(
                  'valid length',
                  'Ensure this field has no more than 6 characters.',
                  (value) => (value as string)?.length <= 6
                )
            }),
          hazmat_packing_group: mixed()
            .nullable()
            .when(['hazmat_hazard_class', 'hazmat_identification_number', 'hazmat_proper_shipping_name'], {
              is: (
                hazmat_hazard_class: string,
                hazmat_identification_number: string,
                hazmat_proper_shipping_name: string
              ) =>
                [hazmat_hazard_class, hazmat_identification_number, hazmat_proper_shipping_name].some(
                  (value) => !!value
                ),
              then: mixed().required('Packing group is required.')
            }),
          hazmat_proper_shipping_name: string()
            .nullable()
            .when(['hazmat_hazard_class', 'hazmat_packing_group', 'hazmat_identification_number'], {
              is: (hazmat_hazard_class: string, hazmat_packing_group: string, hazmat_identification_number: string) =>
                [hazmat_hazard_class, hazmat_packing_group, hazmat_identification_number].some((value) => !!value),
              then: string().nullable().required('Proper shipping name is required.')
            })
        },
        [
          ['hazmat_identification_number', 'hazmat_hazard_class'],
          ['hazmat_identification_number', 'hazmat_packing_group'],
          ['hazmat_identification_number', 'hazmat_proper_shipping_name'],
          ['hazmat_hazard_class', 'hazmat_packing_group'],
          ['hazmat_hazard_class', 'hazmat_proper_shipping_name'],
          ['hazmat_packing_group', 'hazmat_proper_shipping_name']
        ]
      )
    ),
    total_weight_override_value: number()
      .nullable()
      .when('manual_total_weight', {
        is: true,
        then: number().required('Weight is required.').typeError('Weight must be a number.')
      }),
    total_declared_value: number()
      .nullable()
      .typeError('Value must be a dollar value.')
      .when('manual_total_value', {
        is: true,
        then: number()
          .required('Value is required.')
          .test({
            name: 'valid dollar value',
            message: 'Enter a dollar value.',
            test: (value) => validateDollarValue(String(value), true)
          })
          .test({
            name: 'maximum 10 digits',
            message: 'Ensure that there are no more than 10 digits in total.',
            test: (value) => (countTotalDigits(value as number) as number) <= 10
          })
      }),
    total_quantity_override: number()
      .integer()
      .nullable()
      .when('manual_quantity', {
        is: true,
        then: number()
          .integer('Quantity must be an integer.')
          .required('Quantity is required.')
          .typeError('Quantity must be a number.')
          .test({
            name: 'less than or equal to 2147483647',
            message: 'Ensure this value is less than or equal to 2147483647.',
            test: (value) => (value as number) <= 2147483647
          })
      })
  });
};

type ShipmentFormValues = Partial<Shipment> & {
  bypass_carrier_insurance_coverage?: boolean;
  manual_total_weight?: boolean;
  total_weight_override_value?: number;
  total_weight_override_unit?: string;
  manual_total_value?: boolean;
  manual_quantity?: boolean;
  total_quantity_override?: number;
};

type ProductUnitPreferences = {
  weightUnit: ProductWeightUnitEnum;
  lengthUnit: ProductLengthUnitEnum;
  currency: ProductValuePerPieceCurrencyEnum;
  temperatureUnit: ProductTempUnitEnum;
  system: string;
};

type ShipmentLineItemsFormProps = {
  modalState: {mode: string; index: number | null};
  shipmentDetails?: Shipment;
  values?: ShipmentFormValues;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (values: any, props?: unknown) => void;
  lineItems: Partial<ShipmentLineItem>[];
  hasLTL?: boolean;
  hasVLTL?: boolean;
  onCancel: () => void;
  unitPreferences?: ProductUnitPreferences;
  company?: Company;
  submitDisabled?: boolean;
  isWeightRequired?: boolean;
};

/**
 * Shipment line items form
 */
const ShipmentLineItemsForm = ({
  modalState,
  shipmentDetails,
  values,
  onSubmit,
  lineItems,
  hasLTL,
  hasVLTL,
  onCancel,
  unitPreferences,
  company,
  submitDisabled,
  isWeightRequired
}: ShipmentLineItemsFormProps) => {
  const flags = useSwFlags();
  // needs to take the shipment.preferred_currency into account if it is available
  const unitPreferencesWithPreferredCurrency = {
    ...unitPreferences,
    ...(!!shipmentDetails?.preferred_currency && {currency: shipmentDetails?.preferred_currency})
  } as ProductUnitPreferences;

  /** Default values needed for Formik */
  const createDefaultFormValues = (customFields: CustomField[] = []) => ({
    line_items: [createDefaultLineItem(customFields, unitPreferencesWithPreferredCurrency)],
    source: 'SHIPWELL_WEB',
    custom_data: set({}, getCustomDataPath(CustomFieldEntityTypesEnum.ShipmentLineItem), undefined),
    manual_total_weight: !!Number(shipmentDetails?.total_weight_override?.value),
    total_weight_override_value: shipmentDetails?.total_weight_override?.value,
    total_weight_override_unit: shipmentDetails?.total_weight_override?.unit,
    manual_total_value: !!Number(shipmentDetails?.total_declared_value),
    total_declared_value: shipmentDetails?.total_declared_value,
    total_declared_value_currency: shipmentDetails?.total_declared_value
      ? shipmentDetails?.total_declared_value_currency
      : unitPreferencesWithPreferredCurrency?.currency,
    manual_quantity: !!Number(shipmentDetails?.total_quantity_override),
    total_quantity_override: shipmentDetails?.total_quantity_override,
    bypass_carrier_insurance_coverage: shipmentDetails?.metadata?.bypass_carrier_insurance_coverage
  });

  /** Submit shipment line item values */
  const handleSubmit = (values: ShipmentFormValues, props?: unknown) => {
    const payload = {
      line_items: values.line_items as ShipmentLineItemDetail[],
      metadata: {
        open: shipmentDetails?.metadata?.open,
        archived: shipmentDetails?.metadata?.archived,
        tags: shipmentDetails?.metadata?.tags,
        bypass_carrier_insurance_coverage: values.bypass_carrier_insurance_coverage
      },
      total_weight_override: {
        value:
          values?.manual_total_weight && values.total_weight_override_value && values.total_weight_override_value > 0
            ? values.total_weight_override_value
            : null,
        unit: values.total_weight_override_unit ?? shipmentDetails?.total_weight_override?.unit
      },
      total_declared_value: values?.manual_total_value ? values.total_declared_value : null,
      ...(values?.manual_total_value ? {total_declared_value_currency: values?.total_declared_value_currency} : {}),
      total_quantity_override:
        values?.manual_quantity && values?.total_quantity_override && values?.total_quantity_override > 0
          ? values?.total_quantity_override
          : null,
      carrier_reference_code: shipmentDetails?.carrier_reference_code || null
    };

    payload.line_items.forEach((lineItem) => {
      if (lineItem.nmfc_item_code) {
        try {
          lineItem.nmfc_item_code = validateNMFCCode(lineItem.nmfc_item_code) as unknown as string;
        } catch (e) {
          lineItem.nmfc_item_code = '';
        }
      }

      if (lineItem.nmfc_sub_code) {
        try {
          lineItem.nmfc_sub_code = validateNMFCSubCode(lineItem.nmfc_sub_code);
        } catch (e) {
          lineItem.nmfc_sub_code = '';
        }
      }

      if ((lineItem.value_per_piece as unknown as string) === '') {
        lineItem.value_per_piece = null;
      }

      //Empty fields for temp should be converted from '' to null since BE didn't accept empty strings
      if ((lineItem.refrigeration_max_temp as unknown as string) === '') {
        lineItem.refrigeration_max_temp = null;
      }
      if ((lineItem.refrigeration_min_temp as unknown as string) === '') {
        lineItem.refrigeration_min_temp = null;
      }
    });

    if (onSubmit) {
      onSubmit(payload, props);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <>
      <CustomFieldsContextProvider
        companyId={get(company, 'id') as string}
        entityType={CustomFieldEntityTypesEnum.ShipmentLineItem}
      >
        <CustomFieldsContextConsumer>
          {({customFields = []}) => {
            const editableCustomFields = customFields.filter((cf) =>
              isCustomFieldOwner(cf.company, get(company, 'id'))
            );
            const validationSchemaWithCustomFields = createValidationSchema(
              editableCustomFields,
              isWeightRequired,
              (flags?.decimalSupportForShipmentLineItems as boolean) || false,
              hasLTL,
              hasVLTL
            );
            const defaultValuesWithCustomFields = createDefaultFormValues(editableCustomFields);

            // Some shipments might have null or other custom_data, which interferes with these initial
            // values. We need to filter out null custom_data from line items then add proper default
            // values to each line item in the order for validation to work when editing such orders.
            const filteredLineItems = get(values, 'line_items', []).map((lineItem) => {
              const filteredLineItem = {
                ...createDefaultLineItem(customFields, unitPreferencesWithPreferredCurrency),
                ...omitEmptyKeys(lineItem)
              } as ShipmentLineItem;

              const cdPath = `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.ShipmentLineItem)}`;
              set(filteredLineItem, cdPath, {
                ...createDefaultCustomDataValues(customFields),
                ...get(filteredLineItem, cdPath, {})
              });

              return filteredLineItem;
            });

            const setInitialDeclaredValue = (lineItems: Partial<ShipmentLineItem>[]) =>
              lineItems.map((lineItem) => {
                if (isNil(lineItem.value_per_piece) || isNaN(lineItem.value_per_piece)) {
                  return {...lineItem, value_per_piece_currency: unitPreferencesWithPreferredCurrency?.currency};
                }
                return lineItem;
              });

            const filteredValues = {
              ...defaultValuesWithCustomFields,
              ...omitEmptyKeys(values),
              line_items:
                lineItems?.length > 0
                  ? checkHazmatRequired(setInitialDeclaredValue(lineItems))
                  : filteredLineItems.length > 0
                  ? checkHazmatRequired(setInitialDeclaredValue(filteredLineItems))
                  : [createDefaultLineItem(customFields, unitPreferencesWithPreferredCurrency)]
            } as ShipmentFormValues;

            return (
              <Formik
                validationSchema={validationSchemaWithCustomFields}
                initialValues={filteredValues}
                onSubmit={handleSubmit}
                validateOnMount
              >
                {({values, isValid}) => (
                  <>
                    <Form className="shipment-line-item-form" noValidate>
                      <Card title="Shipment Line Items" draggableProvided={null}>
                        <LineItemFields
                          defaultLineItemFieldValues={createDefaultLineItem(
                            customFields,
                            unitPreferencesWithPreferredCurrency
                          )}
                          edit={modalState?.mode === 'edit'}
                          expandedIndex={modalState?.index}
                          shipmentDetails={shipmentDetails}
                          entityType={CustomFieldEntityTypesEnum.ShipmentLineItem}
                          customFields={customFields}
                          isWeightRequired={isWeightRequired}
                          hasLTL={hasLTL}
                          hasVLTL={hasVLTL}
                        />
                      </Card>
                      <Card title="Totals" className="mt-4" draggableProvided={null}>
                        <ShipmentItemTotals
                          unitPreferences={unitPreferencesWithPreferredCurrency}
                          lineItems={values.line_items || []}
                          totalWeight={{
                            value: values.total_weight_override_value,
                            unit: values.total_weight_override_unit as TotalWeightOverrideUnitEnum
                          }}
                          totalValue={values.total_declared_value}
                          totalValueCurrency={
                            values.total_declared_value_currency as BaseShipmentTotalDeclaredValueCurrencyEnum
                          }
                          totalQuantity={values.total_quantity_override}
                          shipmentDetails={shipmentDetails}
                          values={values}
                          showManualOverrideFields
                        />
                      </Card>
                      <ModalFormFooter
                        onPrimaryActionClick={() => handleSubmit(values)}
                        isValid={isValid}
                        primaryActionDisabled={submitDisabled}
                        onCancel={handleCancel}
                        className="relative"
                        primaryActionName="Save Line Items"
                      />
                    </Form>
                  </>
                )}
              </Formik>
            );
          }}
        </CustomFieldsContextConsumer>
      </CustomFieldsContextProvider>
    </>
  );
};

ShipmentLineItemsForm.defaultProps = {
  isWeightRequired: false
};

type StateShipmentDetails = {
  one: Shipment;
};
type ModifiedState = Omit<State, 'shipmentdetails'> & {shipmentdetails: StateShipmentDetails};

export default compose<ShipmentLineItemsFormProps, ShipmentLineItemsFormProps>(
  withStatusToasts,
  withRouter,
  connect((state: ModifiedState) => ({
    unitPreferences: state.userCompany.unitPreferences,
    company: state.userCompany.company
  }))
)(ShipmentLineItemsForm);
