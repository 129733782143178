import {useEffect, useRef, useState, ReactNode, useMemo} from 'react';
import {DeprecatedButton, Button, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {useFormikContext, FieldArray} from 'formik';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import {
  InvoicingShipmentsShipmentIdFreightInvoicesDataRoleEnum,
  Shipment,
  ShipmentChargeLineItem
} from '@shipwell/backend-core-sdk';
import {useOverflow} from 'use-overflow';
import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import {FreightInvoiceChargeLineItemsWithFormMetadata, FreightInvoiceFormData} from '../../types';
import {generateFinancialsLineItemRow, getInvoiceTotal, transformV2ChargeLineItemsToSettlementLineItems} from './utils';
import {NewFinancialLineItemForm} from './NewFinancialLineItemForm';
import usePrevious from 'App/utils/hooks/usePrevious';
import {formatCurrency} from 'App/utils/internationalConstants';
import useToggle from 'App/utils/hooks/useToggle';
import {useSortedChargeCategories} from 'App/data-hooks';
import Loader from 'App/common/shipwellLoader';
import {useGetFullShipmentDetails} from 'App/containers/alertsDashboard/utils/hooks/useGetFullShipmentDetails';

export interface LineItemRowDisplayProps {
  push: (obj: FreightInvoiceChargeLineItemsWithFormMetadata) => void;
  showNewLineItemForm: boolean;
  toggleShowNewLineItemForm: () => void;
  shipmentCurrencyOfRecord: Shipment['preferred_currency'];
  role: InvoicingShipmentsShipmentIdFreightInvoicesDataRoleEnum;
  chargeLineItems?: ShipmentChargeLineItem[];
}

const AddNewFinancialLineItemButton = ({toggleShowNewLineItemForm}: {toggleShowNewLineItemForm: () => void}) => {
  return (
    <DeprecatedButton
      className="mt-2"
      variant="tertiary"
      icon={<SvgIcon name="AddCircleOutlined" />}
      onClick={toggleShowNewLineItemForm}
    >
      Add Line Item
    </DeprecatedButton>
  );
};

const FinancialLineItemsEmptyState = ({toggleShowNewLineItemForm}: {toggleShowNewLineItemForm: () => void}) => (
  <div className="flex min-h-40 flex-col items-center justify-center">
    <div className="text-base text-sw-form-helper-text">No Line Items</div>
    <div className="mt-2">
      <Button variant="secondary" size="sm" onClick={toggleShowNewLineItemForm}>
        Add Line Item
      </Button>
    </div>
  </div>
);

export const LineItemDisplayCell = ({children}: {children: ReactNode}) => {
  const horizontalRef = useRef(null);
  const {refXOverflowing} = useOverflow(horizontalRef);
  return refXOverflowing ? (
    <Tooltip placement="top" wrapperClassname="w-full" tooltipContent={children}>
      <div ref={horizontalRef} className="w-full truncate">
        {children}
      </div>
    </Tooltip>
  ) : (
    <div ref={horizontalRef}>{children}</div>
  );
};
const LineItemRowDisplay = ({
  push,
  showNewLineItemForm,
  toggleShowNewLineItemForm,
  shipmentCurrencyOfRecord,
  role,
  chargeLineItems
}: LineItemRowDisplayProps) => {
  const {values} = useFormikContext<FreightInvoiceFormData>();
  const [invoiceTotal, setInvoiceTotal] = useState<number>();
  const {decimalSupportForShipmentLineItems}: {decimalSupportForShipmentLineItems: boolean} = useSwFlags();
  const {data: chargeCategoryData} = useSortedChargeCategories({
    sendShipwellVersionHeader: decimalSupportForShipmentLineItems
  });
  const selectedChargeLineItems = values.charge_line_items.filter((lineItem) => lineItem.add_to_invoice);
  useEffect(() => {
    const selectedChargeLineItemsInvoiceTotal = getInvoiceTotal(selectedChargeLineItems);
    setInvoiceTotal(selectedChargeLineItemsInvoiceTotal);
  }, [selectedChargeLineItems]);

  const currency = useMemo(
    () =>
      values.charge_line_items.find((lineItem) => !!lineItem.unit_amount.currency)?.unit_amount.currency ||
      shipmentCurrencyOfRecord,
    [shipmentCurrencyOfRecord, values.charge_line_items]
  );

  return (
    <>
      <div
        className={classNames('grid grid-cols-[5%,40%,20%,10%,20%] items-center gap-x-1 pb-2', {
          //don't show the bottom border if the new line item form is open
          'border-b border-b-sw-border': !showNewLineItemForm
        })}
      >
        {values.charge_line_items.map((lineItem, index) => {
          const lineItemDisplayValues = generateFinancialsLineItemRow(lineItem, index, chargeCategoryData);
          return lineItemDisplayValues.map((dataToDisplay, displayValIndex) => (
            <div className="w-full truncate text-left" key={displayValIndex}>
              {displayValIndex === 1 && typeof dataToDisplay === 'string' ? (
                <LineItemDisplayCell>{`${chargeLineItems?.[index]?.charge_code ?? lineItem.charge_code ?? ''} - ${
                  chargeLineItems?.[index]?.unit_name ?? lineItem.description ?? ''
                } (${dataToDisplay})`}</LineItemDisplayCell>
              ) : (
                <LineItemDisplayCell>{dataToDisplay}</LineItemDisplayCell>
              )}
            </div>
          ));
        })}
      </div>
      {showNewLineItemForm ? (
        <NewFinancialLineItemForm
          submitLineItem={push}
          toggleShowNewLineItemForm={toggleShowNewLineItemForm}
          shipmentCurrencyOfRecord={shipmentCurrencyOfRecord}
        />
      ) : null}
      <div className="align-center flex flex-col text-end">
        {!showNewLineItemForm ? (
          <div>
            {role === InvoicingShipmentsShipmentIdFreightInvoicesDataRoleEnum.ServiceProvider ? (
              <AddNewFinancialLineItemButton toggleShowNewLineItemForm={toggleShowNewLineItemForm} />
            ) : null}
          </div>
        ) : null}
        <div className="pt-2 text-xxs uppercase">invoice total</div>
        <div className="text-sm font-bold">{formatCurrency(invoiceTotal, currency)}</div>
      </div>
    </>
  );
};
const FinancialLineItems = ({
  chargeLineItems,
  shipmentId,
  role
}: {
  chargeLineItems?: ShipmentChargeLineItem[];
  shipmentId: string;
  role: InvoicingShipmentsShipmentIdFreightInvoicesDataRoleEnum;
}) => {
  const {setValues, values} = useFormikContext<FreightInvoiceFormData>();
  const previousLineItems = usePrevious(chargeLineItems);
  const [showNewLineItemForm, toggleShowNewLineItemForm] = useToggle(false);
  const {decimalSupportForShipmentLineItems}: {decimalSupportForShipmentLineItems: boolean} = useSwFlags();
  const {isLoading: chargeCategoryQueryLoading} = useSortedChargeCategories({
    sendShipwellVersionHeader: decimalSupportForShipmentLineItems
  });
  const {
    queryInfo: {data: shipment, isLoading: isFullShipmentLoading}
  } = useGetFullShipmentDetails(shipmentId);
  const LINE_ITEM_HEADERS = ['', 'CHARGE ITEM', 'DESCRIPTION', 'QTY', 'COST'];
  useEffect(() => {
    /*Check if v2 shipment financials */
    if (Array.isArray(chargeLineItems) && chargeLineItems?.length > 0 && !isEqual(previousLineItems, chargeLineItems)) {
      setValues({...values, charge_line_items: transformV2ChargeLineItemsToSettlementLineItems(chargeLineItems)});
    }
  }, [chargeLineItems, previousLineItems, setValues, values]);

  if (chargeCategoryQueryLoading || isFullShipmentLoading) {
    return <Loader loading additionalClassNames={['w-full h-[10rem]']} />;
  }
  const hasFinancialLineItems = values.charge_line_items?.length > 0;
  return hasFinancialLineItems ? (
    <>
      <div className="grid grid-cols-[5%,40%,20%,10%,20%] items-center justify-items-start gap-x-1">
        {LINE_ITEM_HEADERS.map((header: string) => (
          <div
            className={classNames('pt-2 text-xxs', {
              //make the cost header line up with the end of the charge cost..
              //row data, so that cost decimal values line up
              'justify-self-end': header === 'COST'
            })}
            key={header}
          >
            {header}
          </div>
        ))}
      </div>
      <FieldArray
        name="charge_line_items"
        render={({push}) => (
          <LineItemRowDisplay
            showNewLineItemForm={showNewLineItemForm}
            toggleShowNewLineItemForm={toggleShowNewLineItemForm}
            push={push}
            shipmentCurrencyOfRecord={shipment?.preferred_currency}
            role={role}
            chargeLineItems={chargeLineItems}
          />
        )}
      />
    </>
  ) : //if there are no line items on the shipment financials, we want to omit the table
  //headers and just show the new line item form.
  !hasFinancialLineItems && showNewLineItemForm ? (
    <FieldArray
      name="charge_line_items"
      render={({push}) => (
        <LineItemRowDisplay
          showNewLineItemForm={showNewLineItemForm}
          toggleShowNewLineItemForm={toggleShowNewLineItemForm}
          push={push}
          shipmentCurrencyOfRecord={shipment?.preferred_currency}
          role={role}
          chargeLineItems={chargeLineItems}
        />
      )}
    />
  ) : (
    <FinancialLineItemsEmptyState toggleShowNewLineItemForm={toggleShowNewLineItemForm} />
  );
};
export default FinancialLineItems;
