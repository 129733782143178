export interface DataDogEnvConfig {
  dataDogEnv: string;
  dataDogClientToken: string;
  dataDogApplicationId: string;
  dataDogTraceOrigins: string;
}

export function getDataDogEnvConfig(): DataDogEnvConfig | null {
  const dataDogEnv = process.env.DATADOG_ENV;
  const dataDogClientToken = process.env.DATADOG_CLIENT_TOKEN;
  const dataDogApplicationId = process.env.DATADOG_APPLICATION_ID;
  const dataDogTraceOrigins = process.env.DATADOG_TRACE_ORIGINS;

  if (!(dataDogEnv && dataDogClientToken && dataDogApplicationId && dataDogTraceOrigins)) {
    return null;
  }

  return {
    dataDogEnv,
    dataDogClientToken,
    dataDogApplicationId,
    dataDogTraceOrigins
  };
}

export function isDataDogEnabledForEnvironment(config: DataDogEnvConfig): boolean {
  const validEnvironments = ['dev', 'prod'];
  return validEnvironments.some((env) => env === config.dataDogEnv);
}

export function isDataDogEnabledForCurrentHostname(): boolean {
  const hostname = window.location.hostname;
  const isFeatureBranch = hostname.includes('frontend-web.shipwell-dev');
  return !isFeatureBranch;
}
