import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import {useLDClient} from 'launchdarkly-react-client-sdk';

export function FeatureFlagsPage() {
  const ldClient = useLDClient();
  const parsedFlags = useSwFlags();

  return (
    <>
      <h2>Feature Flags</h2>
      <div className="grid grid-cols-2">
        <div className="col-span-1">
          <div className="font-bold">Values from LaunchDarkly</div>
          <pre>{JSON.stringify(ldClient?.allFlags(), null, 2)}</pre>
        </div>
        <div className="col-span-1">
          <div className="font-bold">Flag names for the App</div>
          <pre>{JSON.stringify(parsedFlags, null, 2)}</pre>
        </div>
      </div>
    </>
  );
}
