import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import {useUserMe} from 'App/data-hooks';

// These constants are Joe's best guesses
export const PERM_VIEW_FACILITY = 'dock_scheduling.view_facilities';
export const PERM_CREATE_FACILITY = 'dock_scheduling.create_facilities';
export const PERM_UPDATE_FACILITY = 'dock_scheduling.update_facilities';
export const PERM_DELETE_FACILITY = 'dock_scheduling.delete_facilities';
export const PERM_VIEW_FACILITY_CONTACT = 'dock_scheduling.view_facility_contacts';
export const PERM_CREATE_FACILITY_CONTACT = 'dock_scheduling.create_facility_contacts';
export const PERM_UPDATE_FACILITY_CONTACT = 'dock_scheduling.update_facility_contacts';
export const PERM_DELETE_FACILITY_CONTACT = 'dock_scheduling.delete_facility_contacts';

type StandardPerms = {
  canView: boolean;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
};

export type FacilityContactPerms = StandardPerms;

export type FacilityPerms = StandardPerms & {
  contacts: FacilityContactPerms;
};

export function useFacilityPerms(): FacilityPerms {
  const userDetailsQuery = useUserMe();
  const userPermissions = userDetailsQuery.data?.user?.permissions || [];

  const hasFeatureFlag = (useSwFlags() as {fiDockScheduling: boolean}).fiDockScheduling;
  return {
    canView: hasFeatureFlag && userPermissions.includes(PERM_VIEW_FACILITY),
    canCreate: hasFeatureFlag && userPermissions.includes(PERM_CREATE_FACILITY),
    canUpdate: hasFeatureFlag,
    canDelete: hasFeatureFlag,
    contacts: {
      canView: hasFeatureFlag,
      canCreate: hasFeatureFlag,
      canUpdate: hasFeatureFlag,
      canDelete: hasFeatureFlag
    }
  };
}
