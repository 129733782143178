import {PropsWithChildren, JSX, MouseEventHandler} from 'react';
import {Route, IndexRoute, Link, IndexRedirect, WithRouterProps} from 'react-router';
import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import {SupplierEditForm, SupplierContactsEditForm} from 'App/containers/suppliers/forms';
import PageHeader from 'App/common/pageHeader';
import SuppliersList from 'App/containers/suppliers/list';
import Error404Page2 from 'App/common/Error404Page2';
import SideNav from 'App/common/sideNav';
import {useGetSupplier} from 'App/data-hooks';

export const ConditionalSuppliersLink = ({onClick}: {onClick: MouseEventHandler<HTMLButtonElement>}) => {
  const {stmSupplierPortal} = useSwFlags();

  return stmSupplierPortal ? (
    <Link to="/suppliers" activeClassName="active" onClick={onClick}>
      Suppliers
    </Link>
  ) : null;
};

export const SupplierWrapper = ({children}: PropsWithChildren<unknown>): JSX.Element => {
  const {stmSupplierPortal} = useSwFlags();
  return stmSupplierPortal ? (
    <div className="flex flex-col bg-sw-background h-screen-minus-16">{children}</div>
  ) : (
    <Error404Page2 />
  );
};

export declare type SupplierWrapperWithSidebarProps = PropsWithChildren<
  WithRouterProps<{supplier_id: string}, unknown>
>;

export const SupplierWrapperWithSidebar = ({children, params}: SupplierWrapperWithSidebarProps): JSX.Element => {
  const supplierId = params.supplier_id;
  const {supplier, status} = useGetSupplier(supplierId);

  return (
    <SupplierWrapper>
      <PageHeader title={status === 'loading' ? '' : supplier?.name || 'Suppliers'} backRoute="/suppliers" />
      <div className="flex flex-1 overflow-y-auto bg-sw-background">
        <SideNav>
          <Link to={`/suppliers/${supplierId}/details`} activeClassName="active">
            Supplier Info
          </Link>
          <Link to={`/suppliers/${supplierId}/contacts`} activeClassName="active">
            Contacts
          </Link>
        </SideNav>
        <div className="flex-1 p-4">{children}</div>
      </div>
    </SupplierWrapper>
  );
};

export default (
  <Route component={SupplierWrapper}>
    <IndexRoute component={SuppliersList} />
    <Route path="/suppliers/:supplier_id" component={SupplierWrapperWithSidebar}>
      <IndexRedirect to="/suppliers/:supplier_id/details" />
      <Route path="/suppliers/:supplier_id/details" component={SupplierEditForm} />
      <Route path="/suppliers/:supplier_id/contacts" component={SupplierContactsEditForm} />
    </Route>
  </Route>
);
