import {TextInput, useToastManager} from '@shipwell/shipwell-ui';
import {CustomField} from '@shipwell/backend-core-sdk';
import {EditableFieldPopover} from './EditableFieldPopoverProps';
import {CellContext} from '@tanstack/react-table';
import {ShippingDashboardRowWithCustomDataSchema} from '../utils/DashboardUtilTypes';
import {ShipwellCustomData} from 'App/utils/customDataPath';
import {useState} from 'react';
import {getShipment} from 'App/api/shipment/typed';
import useUpdateShipment from 'App/api/shipment/useUpdateShipment';

interface Props {
  info: CellContext<ShippingDashboardRowWithCustomDataSchema, ShipwellCustomData | undefined>;
  dashboardRefetch?: () => void;
  customField: CustomField;
}

export const EditableCustomFieldCell = ({info, dashboardRefetch, customField}: Props) => {
  const customFieldValue = info.getValue()?.shipwell_custom_data?.shipment?.[customField.id || ''];
  const row = info.row.original;
  const [fieldValue, setFieldValue] = useState(customFieldValue);
  const updateShipmentMutation = useUpdateShipment();
  const toastManager = useToastManager();

  const onSave = async () => {
    try {
      const shipment = await getShipment(row.id);
      const updatedShipment = {
        ...shipment,
        custom_data: {
          shipwell_custom_data: {
            ...(row.custom_data?.shipwell_custom_data ?? {}),
            shipment: {
              ...(row.custom_data?.shipwell_custom_data?.shipment ?? {}),
              [String(customField.id)]: fieldValue ?? ''
            }
          }
        }
      };
      await updateShipmentMutation.mutateAsync(
        {shipmentId: row.id, shipment: updatedShipment},
        {
          onSuccess: () => {
            dashboardRefetch?.();
          },
          onError: (error) => {
            console.error('Shipment update error:', error);
            toastManager.enqueueErrorToast(`Failed to update ${customField.label}`);
          }
        }
      );
      dashboardRefetch?.();
    } catch (error) {
      console.error('Shipment update error:', error);
    }
  };

  return (
    <EditableFieldPopover
      value={customFieldValue}
      onSave={onSave}
      onCancel={() => setFieldValue(customFieldValue)}
      isSaveDisabled={customFieldValue === fieldValue}
    >
      <TextInput label={customField.label} value={fieldValue} onChange={(e) => setFieldValue(e.target.value)} />
    </EditableFieldPopover>
  );
};
