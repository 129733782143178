import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import moment from 'moment';
import {
  LegacyShipmentStageStageTypeEnum,
  LegStageStageTypeEnum,
  ServiceStageStageTypeEnum,
  Stage
} from '@shipwell/corrogo-sdk';
import {useQueries} from '@tanstack/react-query';
import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import {useShipmentStagesQuery} from './hooks/useShipmentStagesQuery';
import {useUserPermissions} from 'App/data-hooks';
import LegView from 'App/containers/shipments/components/DashboardSummary/LegView';
import LegacyShipmentView from 'App/containers/shipments/components/DashboardSummary/LegacyShipmentView';
import ServiceView from 'App/containers/shipments/components/DashboardSummary/ServiceView';
import {getStageByResourceId} from 'App/containers/shipments/utils/typed';
import {
  hasDirectlyDependsOnStages,
  isTransloadAnalogServiceStage,
  resourceStageIsLegacyShipmentStage,
  resourceStageIsServiceStage
} from 'App/containers/shipments/components/DashboardSummary/utils';
import {LEGACY_SHIPMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getQueriesStatus} from 'App/utils/queryHelpers';
import {getFullShipmentDetails} from 'App/api/shipment/typed';
import Loader from 'App/common/shipwellLoader';
import {ExpandableContainerViewButton} from 'App/containers/shipments/components/DashboardSummary/ExpandableContainerViewButton';
import {
  VIEW_SHIPMENTS_USER_PERMISSION,
  VIEW_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

function getStageById(stageId: string, stages: Stage[]) {
  return stages.find((stage) => stage.id === stageId);
}

function filterNilStages(stage: Stage | undefined): stage is Stage {
  return stage !== undefined;
}

/**
 * Given a stage and a list of stages, retrieve a flattened and sorted list of upstream ("depends
 * on") and downstream ("dependent") stages, including the given stage. This is used to render a
 * single-file representation of interdependent stages.
 *
 * Ex. 1
 *
 *     A
 *     |
 *     B
 *
 * Results in [A, B].
 *
 * Ex. 2
 *
 *     A
 *   /   \
 *  B ←   C
 *
 * Results in [A, B, C].
 *
 * Ex. 3
 *
 *  A     B
 *   \   /
 *     C ←
 *
 * Results in [A, B, C].
 *
 * Ex. 4
 *
 *     A
 *   /   \
 *  B     C ←
 *   \   /
 *     D
 *
 * Results in [A, C, B, D]. Note the order of the middle rank was reversed because of how we
 * iterate through dependent stages. Order in the same rank does not matter; only ranks need to be
 * in the correct order.
 */
export function flattenDependentAndDependsOnStages(startingStage: Stage, stages: Stage[]) {
  // Store each rank in a Map, which allows numbers as keys. We use a Set to store the stage ids at
  // each rank so that we can immediately and indiscriminately add the currently-being-visited
  // stage to the current rank before visiting its related stages.
  const ranks = new Map<number, Set<string>>();

  /**
   * A simple helper function to add a given stage id to a rank. This operation is the same for the
   * current stage and its list of dependent and depends on stages.
   */
  function addStageToRank(stageId: string, rank: number) {
    if (!ranks.has(rank)) {
      ranks.set(rank, new Set());
    }
    ranks.get(rank)?.add(stageId);
  }

  /**
   * A helper function to map a list of stage ids to a stage and then visit their dependent and
   * depends on stage ids recursively.
   */
  function visitStageIds(stageIds: string[] | undefined, rank: number) {
    stageIds
      ?.map((stageId) => getStageById(stageId, stages))
      .filter(filterNilStages)
      .forEach((stage) => visitDependentAndDependsOnStages(stage, rank));
  }

  /**
   * The recursive visit function. Given a stage and a rank:
   * - Add the stage id to the current rank's set of stage ids if it hasn't been visited
   * - Recursively visit all those depends on and dependent stages, decrementing and incrementing
   *   the rank respectively and adding those stage ids to the appropriate rank.
   *
   * Basically, we go all the way "up" the chain, then all the way "down" the chain, multiple times
   * because of recursion.
   *
   * Note that the order of visitation matters here: we must add the current stage to the ranks map
   * (visit it), then visit all of the dependent and depends on stages in the same manner. We can
   * use a stage id's presence in the ranks map as a marker for the fact that we have visited a
   * stage and handled its dependent and depends on stages.
   */
  function visitDependentAndDependsOnStages(stage: Stage, rank = 0) {
    // If the current stage is already in the ranks map, that means we have visited it already. We
    // need to bail early in that case so we don't go back and forth between stages that reference
    // each other.
    for (const [, stageIds] of ranks) {
      if (stageIds.has(stage.id)) {
        return;
      }
    }

    // Add the current stage id to the ranks map
    addStageToRank(stage.id, rank);

    const nextRank = rank + 1;
    const previousRank = rank - 1;

    // Visit all the dependent and depends on stage ids
    visitStageIds(stage.directly_depends_on_stages, previousRank);
    visitStageIds(stage.directly_dependent_stages, nextRank);
  }

  // Kick off the visit algorithm with the given stage
  visitDependentAndDependsOnStages(startingStage);

  // A Map sorts its keys by insertion order. Since we preemptively populate previous and next
  // ranks, we need to sort the ranks in numerical order.
  // This sorted map can be used to render a view of stage dependencies in something other than a
  // flat line.
  const sortedMap = new Map<number, Set<string>>([...ranks].sort(([aRank], [bRank]) => aRank - bRank));

  // A Map only has forEach, so we need to approximate Map.map by building up a list manually.
  const sortedDependentStages: string[] = [];
  for (const [, stageIds] of sortedMap) {
    sortedDependentStages.push(...stageIds);
  }

  // Finally, map stage ids back to the stages themselves
  return sortedDependentStages.map((stageId) => getStageById(stageId, stages)).filter(filterNilStages);
}

/**
 * A simple hook to encapsulate retrieving stages for a given shipment, finding the stage
 * represented by the given resource id, and finding all related stages to that stage.
 */
export function useRelatedStages(shipmentId: string | undefined, resourceId: string) {
  const shipmentStagesQuery = useShipmentStagesQuery(shipmentId || '');

  if (!shipmentStagesQuery.data) {
    return [];
  }

  const stage = getStageByResourceId(resourceId, shipmentStagesQuery.data);
  if (!stage) {
    return [];
  }
  return flattenDependentAndDependsOnStages(stage, shipmentStagesQuery.data);
}

function useSortedStages(stages: Stage[]) {
  // get a list of the legacy shipment stages, as well as a list of all other stages
  const [legacyShipmentStages, otherStages] = partition(stages, resourceStageIsLegacyShipmentStage);

  const legacyShipmentIds = legacyShipmentStages.map((stage) => stage.legacy_shipment_id);

  // get all the legacy shipment data
  const {isLoading, data} = getQueriesStatus(
    useQueries({
      queries: legacyShipmentIds.map((legacyShipmentId) => ({
        queryKey: [LEGACY_SHIPMENTS_QUERY_KEY, legacyShipmentId],
        queryFn: async () => {
          const response = await getFullShipmentDetails(legacyShipmentId);
          return response.data;
        }
      }))
    })
  );

  // make sure that all queries have finished
  if (isLoading) {
    return [];
  }

  // create a map with the legacy shipment stage and associated delivery date for sorting
  const legacyShipmentStageWithDeliveryDateMap = data.map((shipment) => {
    const deliveryStop = shipment?.stops?.sort((stopOne, stopTwo) => stopTwo.ordinal_index - stopOne.ordinal_index)[0];
    return {
      stage: legacyShipmentStages.find((stage) => stage.legacy_shipment_id === shipment?.id),
      deliveryDateTime:
        deliveryStop?.planned_date && deliveryStop.planned_time_window_start
          ? `${deliveryStop.planned_date} ${deliveryStop.planned_time_window_start}`
          : null
    };
  });

  // also create a map with the service stage and associated delivery date for sorting
  const serviceStageWithDeliveryDateArray = otherStages.filter(resourceStageIsServiceStage).map((serviceStage) => ({
    stage: serviceStage,
    deliveryDateTime: isTransloadAnalogServiceStage(serviceStage.service)
      ? serviceStage.service.actual_datetimes?.end
      : null
  }));

  // merge the arrays
  const stageDeliveryDateMap = [...legacyShipmentStageWithDeliveryDateMap, ...serviceStageWithDeliveryDateArray];

  // sort on delivery date
  const sortedStageDeliveryDateMap = stageDeliveryDateMap.sort((stageA, stageB) => {
    const stageADeliveryDate = stageA.deliveryDateTime ? moment(stageA.deliveryDateTime) : null;
    const stageBDeliveryDate = stageB.deliveryDateTime ? moment(stageB.deliveryDateTime) : null;

    return stageADeliveryDate && stageBDeliveryDate && stageADeliveryDate.isAfter(stageBDeliveryDate) ? 1 : -1;
  });

  // return the list of stages in sorted order, since we no longer care about the delivery date part of it
  return sortedStageDeliveryDateMap.map((sortedStage) => sortedStage.stage);
}

interface StagesViewProps {
  shipmentId?: string;
  resourceId: string;
  stageType: Stage['stage_type'];
}
const ConditionalStageView = ({
  stage,
  shipmentId,
  resourceId,
  index
}: {
  stage: Stage;
  shipmentId?: string;
  resourceId: string;
  index: number;
}) =>
  stage?.stage_type === LegacyShipmentStageStageTypeEnum.LegacyShipment ? (
    <LegacyShipmentView
      shipmentId={shipmentId}
      legacyShipmentId={stage.legacy_shipment_id}
      isActive={stage.legacy_shipment_id === resourceId}
      sequenceNumber={index + 1}
    />
  ) : stage?.stage_type === ServiceStageStageTypeEnum.Service ? (
    <ServiceView
      shipmentId={shipmentId}
      serviceId={stage.service.id}
      serviceType={stage.service.service_type}
      isActive={stage.service.id === resourceId}
      sequenceNumber={index + 1}
    />
  ) : null;

const SortedConditionalStageViewContainer = ({
  relatedStages,
  shipmentId,
  resourceId,
  stageType
}: StagesViewProps & {relatedStages: Stage[]}) => {
  const sortedStages = useSortedStages(relatedStages);
  // If there are no related stages, render this resource based on stage type.
  // Currently this stage type is hard-coded based on where StagesView is rendered from, but in the
  // future, post-new-dashboard, we will need to grab that information from the row record itself in
  // order to be able to differentiate between legs and services.
  // In the case where StagesView is rendered from a resource details page, we can continue to
  // manually pass in the stage type.
  if (isEmpty(sortedStages)) {
    return stageType === LegStageStageTypeEnum.Leg ? (
      <LegView shipmentId={shipmentId} legId={resourceId} isActive sequenceNumber={1} />
    ) : stageType === LegacyShipmentStageStageTypeEnum.LegacyShipment ? (
      <LegacyShipmentView shipmentId={shipmentId} legacyShipmentId={resourceId} isActive sequenceNumber={1} />
    ) : null;
  }
  return (
    <>
      {sortedStages?.map((stage, index) =>
        stage ? (
          <ConditionalStageView
            key={stage.id}
            shipmentId={shipmentId}
            stage={stage}
            resourceId={resourceId}
            index={index}
          />
        ) : null
      )}
    </>
  );
};

function StagesView({shipmentId, resourceId, stageType}: StagesViewProps) {
  const relatedStages = useRelatedStages(shipmentId, resourceId);
  return (
    <>
      <div className="align-center flex flex-col justify-between p-4">
        <SortedConditionalStageViewContainer
          relatedStages={relatedStages}
          shipmentId={shipmentId}
          resourceId={resourceId}
          stageType={stageType}
        />
      </div>
    </>
  );
}

function StagesViewNoPermissions() {
  return (
    <div className="align-center flex flex-col justify-between p-4 text-center">
      <h3>You do not have permission to view this shipment.</h3>
    </div>
  );
}

const StagesExpandableContainerView = ({shipmentId, resourceId, stageType}: StagesViewProps) => {
  const {data: stages, isInitialLoading} = useShipmentStagesQuery(shipmentId || '');
  const stagesWithNilStagesRemoved = stages?.filter(filterNilStages) || [];
  //get the stages without a 'directly depends on' stage, these are our drayage 'containers'
  //for the expandable container view.
  const independentStages = stages?.filter((stage) => !hasDirectlyDependsOnStages(stage)) || [];
  //if there is only one independent stage, or no stages created, just render the standard stages view. There are not
  //multiple 'containers' to view within the shipment.
  if (!isInitialLoading && independentStages.length < 2) {
    return <StagesView shipmentId={shipmentId} resourceId={resourceId} stageType={stageType} />;
  }
  //get the related stages for each independent stage, resulting in a recursive array in which each
  //index represents an independent stage or set of related stages.
  const independentStagesWithRelatedStagesList = independentStages?.map((stage) =>
    flattenDependentAndDependsOnStages(stage, stagesWithNilStagesRemoved)
  );
  if (isInitialLoading) {
    return <Loader loading />;
  }
  return (
    <div className="align-center flex flex-col justify-between p-2">
      {independentStagesWithRelatedStagesList?.map((stages) => {
        const legacyShipmentId =
          //the first stage will be the 'container', or legacy drayage shipment that does not
          //have a directly depends on stage. We use that shipment's id to get the expandable container label.
          (resourceStageIsLegacyShipmentStage(stages?.[0]) && stages?.[0].legacy_shipment_id) || '';
        const containerStageId = stages?.[0]?.id;
        const stageCount = stages.length;
        return (
          <ExpandableContainerViewButton
            key={containerStageId}
            legacyShipmentId={legacyShipmentId}
            stageCount={stageCount}
          >
            <SortedConditionalStageViewContainer
              relatedStages={stages}
              shipmentId={shipmentId}
              resourceId={resourceId}
              stageType={stageType}
            />
          </ExpandableContainerViewButton>
        );
      })}
    </div>
  );
};

const ConditionalStagesView = ({shipmentId, resourceId, stageType}: StagesViewProps) => {
  const {stmDrayageStageCloningWorkflow} = useSwFlags();
  const hasViewShipmentPermission = useUserPermissions([
    VIEW_SHIPMENTS_USER_PERMISSION,
    VIEW_MY_SHIPMENTS_USER_PERMISSION
  ]);
  return !hasViewShipmentPermission[VIEW_SHIPMENTS_USER_PERMISSION] &&
    !hasViewShipmentPermission[VIEW_MY_SHIPMENTS_USER_PERMISSION] ? (
    <StagesViewNoPermissions />
  ) : stmDrayageStageCloningWorkflow ? (
    <StagesExpandableContainerView shipmentId={shipmentId} resourceId={resourceId} stageType={stageType} />
  ) : (
    <StagesView shipmentId={shipmentId} resourceId={resourceId} stageType={stageType} />
  );
};
export default ConditionalStagesView;
