import {DispatchWithoutAction, ReactNode} from 'react';
import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import Loader from 'App/common/shipwellLoader';
import {FreightInvoiceForm} from 'App/containers/settlements/freightInvoices/components/FreightInvoiceForm';
import FreightInvoiceModal from 'App/containers/settlements/freightInvoices/components/FreightInvoiceModal';
import useToggle from 'App/utils/hooks/useToggle';
import {useGetFullShipmentDetails} from 'App/containers/alertsDashboard/utils/hooks/useGetFullShipmentDetails';
import {getUpperCaseRoleValue} from 'App/containers/settlements/freightInvoices/components/FreightInvoiceForm/utils';
import {SettlementsDashboardGeneratedBy} from '@shipwell/backend-core-sdk';

export const CreateFreightInvoiceModalForm = ({
  shipmentId,
  children,
  role
}: {
  shipmentId: string;
  children: (renderProps: {
    toggleShowFreightInvoiceModal: DispatchWithoutAction;
    stmGenerateInvoiceOnBehalfOfCarrier: boolean;
  }) => ReactNode;
  role: SettlementsDashboardGeneratedBy;
}) => {
  const [showFreightInvoiceModal, toggleShowFreightInvoiceModal] = useToggle(false);
  const {
    queryInfo: {data: shipment, isInitialLoading: isInitialLoadingShipment, isFetching: isFetchingShipment}
  } = useGetFullShipmentDetails(shipmentId, {
    enabled: showFreightInvoiceModal && !!shipmentId
  });
  const getChargeLineItems = (role: SettlementsDashboardGeneratedBy) => {
    if (role === SettlementsDashboardGeneratedBy.BookingParty) {
      //this is the customer's side of the carrier financials, since the booking party
      //is creating the invoice
      return shipment?.relationship_to_vendor?.customer_charge_line_items;
    }
    //this is the carrier's side of the carrier financials
    return shipment?.relationship_to_customer?.vendor_charge_line_items;
  };
  const {stmGenerateInvoiceOnBehalfOfCarrier} = useSwFlags();
  return (
    <>
      {children({
        toggleShowFreightInvoiceModal,
        stmGenerateInvoiceOnBehalfOfCarrier: Boolean(stmGenerateInvoiceOnBehalfOfCarrier)
      })}
      <FreightInvoiceModal show={showFreightInvoiceModal} onClose={toggleShowFreightInvoiceModal}>
        {isInitialLoadingShipment || isFetchingShipment ? (
          <Loader loading />
        ) : (
          <FreightInvoiceForm
            onClose={toggleShowFreightInvoiceModal}
            chargeLineItems={getChargeLineItems(role)}
            shipmentId={shipmentId}
            stops={shipment?.stops}
            shipmentLineItems={shipment?.line_items}
            customerName={shipment?.relationship_to_customer?.customer?.name}
            customerReferenceNumber={shipment?.customer_reference_number}
            role={getUpperCaseRoleValue(role)}
          />
        )}
      </FreightInvoiceModal>
    </>
  );
};
