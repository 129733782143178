import {Payload} from 'rollbar';
import {User} from '@shipwell/backend-core-singlerequestparam-sdk';

export function getPersonPayload(user: User | undefined) {
  if (!user) return;

  const userName = `${user.first_name || ''} ${user.last_name || ''}`;
  const personConfig: Payload['person'] = {
    id: user.id || '',
    username: userName,
    email: user.email || ''
  };

  return personConfig;
}
