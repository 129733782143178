import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import pick from 'lodash/pick';

/**
 * This util helps us create a higher order route container
 * @param  {Array} flagsToConsume an array of strings for
 * each flag the component is dependant on, in camelCase
 *
 */
// eslint-disable-next-line react/display-name
const withFlags =
  (...flagsToConsume) =>
  (Component) =>
  // eslint-disable-next-line react/display-name
  (props) => {
    const flags = useSwFlags();
    const consumedFlags = pick(flags, flagsToConsume);
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...consumedFlags} {...props} />;
  };

export default withFlags;
