import {FormikSelect} from '@shipwell/shipwell-ui';
import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import {Field, useFormikContext} from 'formik';
import {NewStageFormValues} from './utils';
import {preferredCurrencyOptions} from 'App/containers/Book/FormSections/Financials/components/utils';

const options = preferredCurrencyOptions.map(({name, id}) => ({label: name, value: id}));

export const NewStageCurrencyOfRecordField = () => {
  const {setFieldValue} = useFormikContext<NewStageFormValues>();
  const {stmShipmentCurrencyOfRecord} = useSwFlags();
  if (!stmShipmentCurrencyOfRecord) return null;

  return (
    <Field
      label="Currency of Record"
      component={FormikSelect}
      options={options}
      onChange={(newValue: string) => {
        setFieldValue('preferred_currency', newValue);
      }}
      name="preferred_currency"
      simpleValue
      required
    />
  );
};
