import {Dictionary, LogArgument} from 'rollbar';

export interface IgnoreRule {
  isUncaught: boolean;
  args: LogArgument[];
  item: Dictionary;
}

export function checkIgnore(params: IgnoreRule) {
  if (hasCefSharpError(params.args)) {
    return true;
  }

  return false;
}

// Identify if this is an error related to CefSharp library
// https://trackjs.com/javascript-errors/object-not-found-matching-id-methodname-paramcount/
function hasCefSharpError(args: LogArgument[]) {
  if (!Array.isArray(args)) return false;
  return args.some((arg) => {
    if (!arg) return false;
    const message = getErrorMessage(arg);
    const objectNotFoundPattern = /Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/;
    return objectNotFoundPattern.test(message);
  });
}

function getErrorMessage(arg: LogArgument) {
  if (!arg) return '';
  if (typeof arg === 'string') return arg;
  if (arg instanceof Error) return arg.message;
  if (typeof arg === 'object' && 'message' in arg) return arg.message as string;
  return String(arg);
}
