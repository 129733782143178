import {Configuration, Dictionary, LogArgument} from 'rollbar';
import {RollbarEnvConfig} from './rollBarEnvConfig';
import {checkIgnore} from './ignoreRules';

export function getRollbarConfig(rollbarEnvConfig: RollbarEnvConfig) {
  const config: Configuration = {
    accessToken: rollbarEnvConfig.accessToken,
    environment: rollbarEnvConfig.environment,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          source_map_enabled: true,
          guess_uncaught_frames: true,
          code_version: process.env.VERSION
        }
      }
    },
    checkIgnore: (isUncaught: boolean, args: LogArgument[], item: Dictionary) => checkIgnore({isUncaught, args, item})
  };

  return config;
}
