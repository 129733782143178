/* eslint-disable camelcase */
import {Route, Redirect, IndexRoute} from 'react-router';
import SignOut from 'App/components/SignOut';
import RequireAuth from 'App/components/RequireAuth';
import ForgotPassword from 'App/containers/ForgotPassword';
import withUnauthenticatedUser from 'App/containers/authentication/components/withUnauthenticatedUser';
import SetPassword from 'App/components/SetPassword';
import AuthReset from 'App/containers/AuthReset';
import ConfigurationErrorPageWrapper from 'App/containers/authentication/ConfigurationErrorPageWrapper';
import VerifyEmail from 'App/components/VerifyEmail';

import Account from 'App/components/Account';
import AnalyticsDashboard from 'App/containers/analytics/index';

//NewShipment
import {CreateQuickQuote, NewQuoteStopsAndLineItems} from 'App/containers/quotes/create';
import {EditQuickQuote} from 'App/containers/quotes/edit';
import NewShipment from 'App/containers/Book/NewShipment';
import NewLoad from 'App/containers/Book/NewLoad';
import ManualRate from 'App/containers/Book/ManualRate';
import ConfirmShipment from 'App/containers/Book/ConfirmShipment';

// Shipment
import {SHIPMENT_DETAILS_TABS} from 'App/containers/Shipment/ShipmentDetails';
import SendShipmentDocuments from 'App/containers/Shipment/SendShipmentDocuments';
import NewShipmentDocument from 'App/containers/Shipment/NewShipmentDocument';
import ShipmentDocDetail from 'App/containers/Shipment/ShipmentDocDetail';
import Shipment_Doc_Update from 'App/containers/Shipment/Shipment_Doc_Update';
import ExternalTracking from 'App/containers/ExternalTracking/Tracking';
import Marketplace from 'App/containers/Marketplace';
import MarketplaceDetails from 'App/containers/Marketplace/components/MarketplaceDetails';
import AuctionDetails from 'App/containers/Marketplace/components/AuctionDetails';
import InstantRates from 'App/containers/Marketplace/components/InstantRates';

import MobileRedirect from 'App/containers/MobileRedirect/MobileRedirect';
import AdminLogin from 'App/containers/AdminLogin/AdminLogin';
import {Impersonation} from 'App/containers/Impersonation';

// shipments
import RenderShipmentVersion from 'App/containers/shipments/components/RenderShipmentVersion';
import ConditionalLegDetails from 'App/containers/shipments/details/LegDetails';
import {EditContainers} from 'App/containers/shipments/drayage/components/ContainersForm';
import {drayageShipmentTabRoutes, drayageLegTabRoutes} from 'App/containers/shipments/details/utils/DetailsSchemas';

import LoadBoard from 'App/containers/LoadBoard/LoadBoard';
import LoadBoardDetail from 'App/containers/LoadBoard/LoadBoardDetail';

//Settings
import Settings from 'App/containers/Settings/Settings';
import User_Permissions from 'App/containers/Settings/User_Permissions';
import Profile from 'App/containers/Settings/Profile';
import FedExRegistration from 'App/containers/fedex/registration';
import UPSRegistration from 'App/containers/ups/registration';
import UberFreightRegistration from 'App/containers/uber/registration';
import ParcelManifest from 'App/components/parcelManifest';

import AutomationUIElements from 'App/containers/automationUI';

import ManageCustomers from 'App/containers/Settings/ManageCustomers/ManageCustomers';
import EditCustomer from 'App/containers/Settings/ManageCustomers/EditCustomer';
import NewCustomer from 'App/containers/Settings/ManageCustomers/NewCustomer';

import ManageDrivers from 'App/containers/Settings/ManageDrivers/ManageDrivers';
import NewDriver from 'App/containers/Settings/ManageDrivers/NewDriver';

import ManageEquipment from 'App/containers/Settings/ManageEquipment/ManageEquipment';
import EditEquipment from 'App/containers/Settings/ManageEquipment/EditEquipment';

//Dispatch
import Dispatch from 'App/containers/Dispatch/Dispatch';
import TrackShipment from 'App/containers/Dispatch/TrackShipment';

import Error404Page from 'App/common/Error404Page';
import Error404Page2 from 'App/common/Error404Page2';

import {permViewCustomers} from 'App/utils/globals';
// Route auth is an array of required permissions

import SupplierShipmentExternalContainer from 'App/containers/supplierShipments/externalContainer';
import SupplierShipment from 'App/containers/supplierShipments';
import CreateSupplierShipment from 'App/containers/supplierShipments/create';
import SupplierShipmentDetails from 'App/containers/supplierShipments/details';
import SupplierShipmentList from 'App/containers/supplierShipments/list';

import ExternalTenderRequest from 'App/containers/externalTenderRequest';
import RfpBidRequestConditional from 'App/containers/userCompany/rfps/RfpBidRequest/RfpBidRequest';
import RfpCarrierList from 'App/containers/userCompany/rfps/RfpCarrierList';
import AmazonFreightRegistration from 'App/containers/amazon/registration';
import {ConditionalPlaygroundRoute} from 'App/components/TypedTable/playground/ConditionalPlaygroundRoute';
import ProcessCode from 'App/components/ProcessCode';
import {SmsToLocateContainer} from 'App/containers/SmsToLocate';
import {PlaygroundPage} from 'App/containers/playground/playground';

import {basePath} from 'App/api/config';

const isDev = !basePath?.startsWith('https://api.shipwell.com/');

import {ConditionalDynamicFormPlayGroundRoute} from 'App/components/dynamicForms/ConditionalPlaygroundRoute';
import {FormikWithHook} from 'App/containers/playground/formikWithHook/formikWithHook';
import {MultiSelectWithColors} from 'App/containers/playground/multiSelectWithColors/multiSelectWithColors';
import NewParcelMarketplace from 'App/containers/Marketplace/components/NewParcelMarketplace';
import {DevToolsPage} from 'App/containers/devTools/devTools';
import {FeatureFlagsPage} from 'App/containers/devTools/featureFlags/featureFlags';
import {InfoPage} from 'App/containers/devTools/generalInfo/generalInfo';

const tryImportChunk = (importChunk) => async (nextState, cb) => {
  try {
    const routes = await importChunk();
    cb(null, routes.default);
  } catch (error) {
    console.error('Error loading chunk; reloading the page', error);
    if (process.env.NODE_ENV === 'production') {
      window.location.reload();
    }
  }
};

export default (
  <div>
    <Redirect from="/" to="/dashboard" />
    <Redirect from="/dashboard/" to="/dashboard" />
    <Redirect from="/index.html" to="/dashboard" />
    <Route path="/admin" component={AdminLogin} />

    {isDev && (
      <Route path="/playground" component={PlaygroundPage}>
        <Route path="formik" component={FormikWithHook} />
        <Route path="multiselect" component={MultiSelectWithColors} />
      </Route>
    )}

    <Route path="/impersonate" component={Impersonation} />
    <Route path="/signout" component={SignOut} />
    <Route path="/tracking/:id" component={ExternalTracking} />
    <Route path="/mobile" component={MobileRedirect} />
    <Route path="/set-password" component={SetPassword} />
    <Route path="/reset-password" component={AuthReset} />
    <Route path="/forgot-password" component={withUnauthenticatedUser(ForgotPassword)} />
    <Route path="/password-reset" component={withUnauthenticatedUser(ForgotPassword)} />
    <Route path="/verify-email" component={VerifyEmail} />
    <Route path="/signin-error" component={withUnauthenticatedUser(ConfigurationErrorPageWrapper)} />
    <Route path="/supplier-shipments/auth" component={SupplierShipmentExternalContainer}>
      <Route path="/supplier-shipments/create" component={CreateSupplierShipment} />
      <Route path="/supplier-shipments/:shipment_id" component={SupplierShipmentDetails} />
    </Route>
    <Route path="/tender-request/:tenderId" component={ExternalTenderRequest} />
    <Route path="/account" component={RequireAuth(Account)}>
      <Route path="/dev-tools" component={DevToolsPage}>
        <Route path="feature-flags" component={FeatureFlagsPage} />
        <Route path="info" component={InfoPage} />
      </Route>

      <Route path="/analytics" component={AnalyticsDashboard} />
      <Route path="/shipments/:shipment_id" component={RenderShipmentVersion} />
      <Route path="/shipments/:shipment_id/send-documents" component={SendShipmentDocuments} />
      <Route path="/shipments/:shipment_id/new-document" component={NewShipmentDocument} />
      <Route path="/shipments/:shipment_id/documents/:doc_id" component={ShipmentDocDetail} />
      <Route path="/shipments/:shipment_id/documents/:doc_id/edit" component={Shipment_Doc_Update} />
      {[...SHIPMENT_DETAILS_TABS, ...drayageShipmentTabRoutes].map((tab) => (
        <Route key={tab} path={`/shipments/:shipment_id/${tab}`} component={RenderShipmentVersion} />
      ))}
      <Route path="/leg/:leg_id" component={ConditionalLegDetails} />
      {drayageLegTabRoutes.map((tab) => (
        <Route key={tab} path={`/leg/:leg_id/${tab}`} component={ConditionalLegDetails} />
      ))}
      <Route path="/shipments/:shipment_id/containers/edit" component={EditContainers} />
      <Route path="/new-quote" component={CreateQuickQuote}>
        <IndexRoute component={NewQuoteStopsAndLineItems} />
      </Route>
      <Route path="/new-quote-parcel" component={CreateQuickQuote}>
        <IndexRoute component={NewQuoteStopsAndLineItems} />
      </Route>
      <Redirect from="/book/new-shipment" to="/new-quote" />
      <Route path="/new-shipment/:shipment_id" component={NewShipment} />
      <Route path="/new-shipment-ftl" component={NewShipment} />
      <Route path="/new-shipment-ltl" component={NewShipment} />
      <Route path="/new-shipment-vltl" component={NewShipment} />
      <Route path="/new-shipment-drayage" component={NewShipment} />
      <Route path="/new-shipment-intermodal" component={NewShipment} />
      <Route path="/new-shipment-rail" component={NewShipment} />
      <Route path="/new-shipment" component={NewShipment} />
      <Route path="/new-load" component={NewLoad} />
      <Redirect from="/shipments/:shipment_id/quotes" to="/marketplace/:shipment_id/bids" />
      {/* <Route path="/shipments/:shipment_id/quotes" component={ShipmentQuotes} /> */}
      <Redirect from="/marketplace/:shipment_id/fedex-rates" to="/marketplace/:shipment_id/parcel-rates" />
      <Redirect from="/marketplace/:shipment_id/ups-rates" to="/marketplace/:shipment_id/parcel-rates" />
      <Redirect from="/marketplace/:shipment_id/usps-rates" to="/marketplace/:shipment_id/parcel-rates" />
      <Route path="/marketplace/:shipment_id/parcel-rates" component={NewParcelMarketplace} />
      <Route component={Marketplace}>
        <Route path="/marketplace/:shipment_id/instant-rates" component={InstantRates} />
        <Route path="/marketplace/:shipment_id/bids" component={MarketplaceDetails} />
        <Route path="/marketplace/:shipment_id/bids/:bid_id" component={AuctionDetails} />
      </Route>
      <Redirect
        from="/shipments/:shipment_id/my-marketplace/customers"
        to="/shipments/:shipment_id/my-marketplace/vendors"
      />
      <Redirect from="/shipments/:shipment_id/my-marketplace/vendors" to="/marketplace/:shipment_id/bids" />
      <Redirect from="/shipments/:shipment_id/my-marketplace/request" to="/marketplace/:shipment_id/bids" />
      <Route path="/marketplace/:shipment_id/manual-rate" component={ManualRate} />
      <Route path="/shipments/:shipment_id/edit" component={EditQuickQuote}>
        <IndexRoute component={NewQuoteStopsAndLineItems} />
      </Route>
      <Route path="/shipments/:shipment_id/confirm" component={ConfirmShipment} />
      <Route path="/supplier-shipments" component={SupplierShipment}>
        <IndexRoute component={SupplierShipmentList} />
      </Route>
      <Route path="/manage_customers" auth={[permViewCustomers]} component={ManageCustomers} />
      <Route path="/manage_customers/:customer_id" auth={[permViewCustomers]} component={EditCustomer} />
      <Route path="/new_customer" auth={[permViewCustomers]} component={NewCustomer} />
      <Route path="/automationUI" component={AutomationUIElements} />
      <Route path="/manage_drivers" component={ManageDrivers} />
      <Route path="/manage_drivers/new_driver" component={NewDriver} />
      <Route path="/manage_equipment" component={ManageEquipment} />
      <Route path="/manage_equipment/:equipment_type/:equipment_id" component={EditEquipment} />
      <Route path="/manage_equipment/:equipment_type/new_equipment" component={EditEquipment} />
      <Route path="/settings" component={Settings}>
        <Route path="/settings/user_permissions" component={User_Permissions} />
        <Route path="/settings/push_notifications" />
        <Route path="/settings/profile" component={Profile} />
      </Route>
      <Route path="/fedex" component={FedExRegistration} />
      <Route path="/fedex/:fedexId" component={FedExRegistration} />
      <Route path="/fedex/clone/:cloneFedexId" component={FedExRegistration} />
      <Route path="/ups" component={UPSRegistration} />
      <Route path="/uber" component={UberFreightRegistration} />
      <Route path="/amazon" component={AmazonFreightRegistration} />
      <Route path="/pickup/:pickupId/manifest" component={ParcelManifest} />
      <Route path="/dispatch" component={Dispatch} />
      <Route path="/dispatch/:shipment_id" component={TrackShipment} />
      <Redirect from="/bids/:bid_id" to="/load-board" />
      <Redirect from="/bids" to="/load-board" />
      <Route path="/load-board" component={LoadBoard} />
      <Route path="/load-board/:load_board_id" component={LoadBoardDetail} />
      <Route path="/rfp-carrier-bid-requests" component={RfpCarrierList} />
      <Route path="/rfp-carrier-bid-requests/:bidId" component={RfpBidRequestConditional} />
      {ConditionalPlaygroundRoute}
      {ConditionalDynamicFormPlayGroundRoute}

      <Route path="*" component={Error404Page} />
    </Route>
    <Route
      path="/addressbook"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "addressbook" */ 'App/containers/addressBook/routes')
      )}
    />
    <Redirect from="/addressbookv2" to="/addressbook" />
    <Route
      path="/orders"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "purchase-orders" */ 'App/containers/orders/views/OrdersListView/routes')
      )}
    />
    <Route
      path="/purchase-orders"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "purchase-orders" */ 'App/containers/purchaseOrders/routes')
      )}
    />
    <Route
      path="/sales-orders"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "sales-orders" */ 'App/containers/orders/salesOrders/routes')
      )}
    />
    <Route
      path="/purchase-orders-corrogo"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "purchase-orders-corrogo" */ 'App/containers/orders/purchaseOrdersCorrogo/routes')
      )}
    />
    <Redirect from="/_purchase-orders" to="/purchase-orders" />
    <Route
      path="/customers"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "customers" */ 'App/containers/customers/routes')
      )}
    />
    <Route
      path="/carriers"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() => import(/* webpackChunkName: "carriers" */ 'App/containers/carriers/routes'))}
    />
    <Redirect from="/_carriers" to="/carriers" />
    <Route
      path="/pricing-intel"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "pricing-intel" */ 'App/containers/pricingIntel/routes')
      )}
    />
    <Redirect from="/_pricing-intelligence" to="/pricing-intel" />
    <Route
      path="/equipment"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "equipment" */ 'App/containers/equipment/routes')
      )}
    />
    <Route
      path="/drivers"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() => import(/* webpackChunkName: "drivers" */ 'App/containers/drivers/routes'))}
    />
    <Route
      path="/products"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() => import(/* webpackChunkName: "products" */ 'App/containers/products/routes'))}
    />
    <Redirect from="/productsv2" to="/products" />
    <Route
      path="/service-providers"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "service-providers" */ 'App/containers/serviceProviders/routes')
      )}
    />
    <Route
      path="/company"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "user-company" */ 'App/containers/userCompany/routes')
      )}
    />
    <Route
      path="/facilities"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "facilities" */ 'App/containers/facilities/routes')
      )}
    />
    <Route
      path="/appointments"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "appointments" */ 'App/containers/appointments/routes')
      )}
    />
    <Route
      path="/user"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "user-profile" */ 'App/containers/userProfile/routes')
      )}
    />
    <Route
      path="/dashboard"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "dashboard" */ 'App/containers/Dashboard/routes')
      )}
    />
    <Route
      path="/automation"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "workflows" */ 'App/containers/workflows/routes')
      )}
    />
    <Route
      path="/bid-manager"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "carrierBids" */ 'App/containers/carrierBids/routes')
      )}
    />
    <Route
      path="/compass"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "alertsDashboard" */ 'App/containers/alertsDashboard/routes')
      )}
    />
    <Route
      path="/templates"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "templateManagement" */ 'App/containers/templateManagement/routes')
      )}
    />
    <Route
      path="/drayage-shipment"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "drayageShipment" */ 'App/containers/shipments/routes')
      )}
    />
    <Route
      path="/load-optimizations"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "loadOptimization" */ 'App/containers/loadOptimization/routes')
      )}
    />
    <Route
      path="/tenders"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() => import(/* webpackChunkName: "tenders" */ 'App/containers/tenders/routes'))}
    />

    <Route
      path="/settlements"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(
          /* webpackChunkName: "settlements" */ 'App/containers/settlements/freightInvoices/components/SettlementsDashboard/routes'
        )
      )}
    />
    <Route
      path="/suppliers"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "suppliers" */ 'App/containers/suppliers/routes')
      )}
    />
    <Route
      path="/shipment"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "shipment" */ 'App/containers/shipmentsV2/routes')
      )}
    />
    <Route
      path="/orders"
      component={RequireAuth(Account)}
      getChildRoutes={tryImportChunk(() =>
        import(/* webpackChunkName: "shipment" */ 'App/containers/purchaseOrdersv2/routes')
      )}
    />
    <Route path="/auth/processcode" component={ProcessCode} />
    <Route path="/shipment-sms-tracking/:shipmentId" component={SmsToLocateContainer} />

    <Route path="*" component={Error404Page2} />
  </div>
);
