import {RollbarEnvConfig} from './rollBarEnvConfig';

export function getRollbarEnvConfig() {
  const rollbarEnv = process.env.ROLLBAR_ENV;
  const rollbarAccessToken = process.env.ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN;

  if (!(rollbarEnv && rollbarAccessToken)) {
    return null;
  }

  const config: RollbarEnvConfig = {
    accessToken: rollbarAccessToken,
    environment: rollbarEnv
  };

  return config;
}

export function isRollbarEnabledForEnvironment(rollbarEnvConfig: RollbarEnvConfig) {
  // https://rollbar.com/shipwell/frontend-web-app/settings/environments/
  const validEnvironments = ['dev', 'production'];
  const isValidEnvironment = validEnvironments.some((env) => env === rollbarEnvConfig.environment);
  return isValidEnvironment;
}

export function isRollbarEnabledForCurrentHostname() {
  const hostname = window.location.hostname;
  const isFeatureBranch = hostname.includes('frontend-web.shipwell-dev');
  return isFeatureBranch ? false : true;
}
