import {useRemoteLoading} from '../useRemoteLoading/useRemoteLoading';

export interface DashboardLoadingRibbonProps {
  isLoading?: boolean;
}

export function DashboardLoadingRibbon(props: DashboardLoadingRibbonProps) {
  const remoteLoading = useRemoteLoading();

  if (!props.isLoading && !remoteLoading.isLoading) return null;

  return (
    <div
      className="absolute w-[-webkit-fill-available] z-10 mt-[-7px] h-[6px] bg-sw-active overflow-hidden"
      role="progressbar"
    >
      <div
        className="mx-auto h-full w-3/4 animate-horizontal-bounce bg-gradient-to-r from-transparent via-sw-active-light-hex to-transparent"
        aria-hidden
      />
    </div>
  );
}
