import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getDisplayName, compose} from 'recompose';
import get from 'lodash/get';
import {Sidebar} from '@shipwell/shipwell-ui';
import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import SidebarActions from '../Actions';
import SidebarNotes from '../Notes';
import SidebarMessages from '../Messages';
import SidebarDocuments from '../Documents';
import SidebarCases from '../Cases';
import SidebarTracking from '../Tracking';
import SidebarStages from '../Stages';
import EmbeddedPricingIntel from '../EmbeddedPricingIntel';
import withFlags from 'App/utils/withFlags';
import {useHasUserPermissions} from 'App/data-hooks/users/useUserPermissions';
import {VIEW_SHIPMENT_FINANCIALS_USER_PERMISSION} from 'App/components/permissions/PermissionsFallback/constants';

// A list of tuples of (button, Component) pairs to be rendered in the sidebar as tabs
const COLLABORATION_COMPONENTS = [
  [{name: 'ActionFilled', label: 'Actions'}, SidebarActions],
  [{name: 'Messages', label: 'Messages'}, SidebarMessages],
  [{name: 'Clipboard', label: 'Documents'}, SidebarDocuments],
  [{name: 'DocumentBlank', label: 'Notes'}, SidebarNotes],
  [{name: 'FolderFilled', label: 'Cases'}, SidebarCases],
  [{name: 'Stages', label: 'Stages'}, SidebarStages],
  [{name: 'Money', label: 'Pricing Intel'}, EmbeddedPricingIntel],
  [{name: 'Wifi', label: 'Tracking'}, SidebarTracking]
];

const Collaboration = ({
  isShipwell,
  isOpen,
  selectedIndex,
  onCollaborationClose,
  onCollaborationSelect,
  modeMultiModeDashboard,
  ...props
}) => {
  const {stmFinancialTenderingUserPermission} = useSwFlags();
  const hasFinancialViewPermissions = useHasUserPermissions([VIEW_SHIPMENT_FINANCIALS_USER_PERMISSION]);
  const canViewFinancials = stmFinancialTenderingUserPermission ? hasFinancialViewPermissions : true;

  // Reduce the list of collaboration component tuples to two lists of buttons and components, which
  // is what we need to render the sidebar such that button and tab are connected as expected.
  const [buttons, sidebarComponents] = COLLABORATION_COMPONENTS.reduce(
    ([buttons, sidebarComponents], [button, Component]) => {
      if (
        (Component === SidebarCases && !isShipwell) ||
        (Component === SidebarStages && !modeMultiModeDashboard) ||
        (Component === EmbeddedPricingIntel && !canViewFinancials)
      ) {
        return [buttons, sidebarComponents];
      }

      return [
        [...buttons, button],
        [...sidebarComponents, <Component {...props} key={`sidebar-${getDisplayName(Component)}`} />]
      ];
    },
    [[], []]
  );

  return (
    <Sidebar
      className="shipment__collaboration z-10"
      buttons={buttons}
      initialIsOpen={isOpen}
      initialSelectedIndex={selectedIndex}
      onClose={onCollaborationClose}
      onSelect={onCollaborationSelect}
      tooltipPlacement="left"
    >
      {sidebarComponents}
    </Sidebar>
  );
};

Collaboration.propTypes = {
  onCollaborationClose: PropTypes.func,
  onCollaborationSelect: PropTypes.func,
  isOpen: PropTypes.bool,
  isShipwell: PropTypes.bool,
  selectedIndex: PropTypes.number,
  modeMultiModeDashboard: PropTypes.bool
};

export default compose(
  connect((state) => ({
    isShipwell: get(state, 'userCompany.company.is_shipwell', false)
  })),
  withFlags('modeMultiModeDashboard')
)(Collaboration);
