import {ReactNode} from 'react';
import {Provider} from '@rollbar/react';
import {getRollbarEnvConfig, isRollbarEnabledForEnvironment, isRollbarEnabledForCurrentHostname} from './helpers';
import {getRollbarConfig} from './configs';
import {PersonUpdater} from './personUpdater/personUpdater';
export interface RollbarProviderProps {
  children: ReactNode;
}

export function RollbarProvider(props: RollbarProviderProps) {
  const rollbarEnvConfig = getRollbarEnvConfig();

  if (!rollbarEnvConfig) {
    return props.children;
  }

  if (!isRollbarEnabledForEnvironment(rollbarEnvConfig)) {
    return props.children;
  }

  if (!isRollbarEnabledForCurrentHostname()) {
    return props.children;
  }

  const rollbarConfig = getRollbarConfig(rollbarEnvConfig);

  return (
    <Provider config={rollbarConfig}>
      <PersonUpdater />
      {props.children}
    </Provider>
  );
}
