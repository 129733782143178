import {Context} from '@rollbar/react';
import {RollbarToolsContents} from './rollbarToolsContents';
import {useContext} from 'react';

export function RollbarTools() {
  const rollbarContext = useContext(Context);

  return (
    <div className="inline-flex flex-col gap-2">
      <h3>Rollbar ({rollbarContext ? 'initialized' : 'not initialized'})</h3>

      {rollbarContext && <RollbarToolsContents />}
    </div>
  );
}
