import {isInitialized} from '@fullstory/browser';
import {RollbarTools} from './rollbarTools/rollbarTools';
import {DatadogTools} from './datadogTools/datadogTools';
export function InfoPage() {
  return (
    <>
      <h2>General Info</h2>
      <table className="border-separate border-spacing-2">
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Version</td>
            <td>{process.env.VERSION}</td>
          </tr>
          <tr>
            <td>Fullstory</td>
            <td>{isInitialized() ? 'true' : 'false'}</td>
          </tr>
        </tbody>
      </table>

      <DatadogTools />
      <RollbarTools />
    </>
  );
}
