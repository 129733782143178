import React, {ReactNode, useState} from 'react';
import {Button, Popover, SvgIcon} from '@shipwell/shipwell-ui';

interface EditableFieldPopoverProps<T = string> {
  value: T;
  onSave: () => Promise<void>;
  onCancel?: () => void;
  isSaveDisabled?: boolean;
  children: ReactNode;
}

export const EditableFieldPopover = <T,>({
  value,
  onSave,
  onCancel,
  children,
  isSaveDisabled
}: EditableFieldPopoverProps<T>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSave = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    await onSave();
  };

  return (
    <Popover
      placement="bottom-start"
      showArrow={false}
      portal
      trigger={({
        isOpen,
        setIsOpen,
        setTriggerElement
      }: {
        isOpen: boolean;
        setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
        setTriggerElement: (el: HTMLElement | null) => void;
      }) => {
        const selectedCellStyle = isOpen ? ' border-1 border-sw-primary' : '';
        return (
          <div
            className={`flex p-2 absolute top-0 left-0 w-full h-full${selectedCellStyle}`}
            ref={setTriggerElement}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen((prev) => !prev);
            }}
          >
            {value || '--'}
          </div>
        );
      }}
    >
      {({setIsOpen}) => (
        <div>
          <div className="p-4" onClick={(e) => e.stopPropagation()}>
            {children}
          </div>
          <hr className="border-t border-gray-300 border w-full my-0 py-1" />

          <div className="flex justify-end p-2">
            <Button
              size="sm"
              variant="tertiary"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
                onCancel?.();
              }}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="primary"
              type="submit"
              onClick={async (e) => {
                setIsSubmitting(true);
                await handleSave(e);
                setIsSubmitting(false);
                setIsOpen((prev) => !prev);
              }}
              disabled={isSaveDisabled || isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <SvgIcon name="Refresh" className="rotate" /> Saving...
                </>
              ) : (
                'Save'
              )}
            </Button>
          </div>
        </div>
      )}
    </Popover>
  );
};
