import {JSX} from 'react';
import isNil from 'lodash/isNil';
import {AppointmentStatusEnum} from '@shipwell/tempus-sdk';
import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import {useQueryClient} from '@tanstack/react-query';
import {RescheduleFCFS} from '../RescheduleFCFS';
import {AppointmentDetailsPaper} from 'App/components/appointments';
import {
  useCancelAppointmentMutation,
  useShipmentsStopAppointmentQuery,
  useCarrierAssignment,
  useShipmentStopFacilityQuery,
  useUserMe
} from 'App/data-hooks';
import {formatParts} from 'App/data-hooks/appointments/utils';
import {formatDate} from 'App/utils/dateTimeGlobalsTyped';
import {AppointmentButtonGroup} from 'App/components/Buttons';
import {useFacilitiesShipmentMatching} from 'App/data-hooks/facilities/useFacilitiesShipmentMatching';
import {SHIPMENT_STOPS_APPOINTMENT_QUERY_KEY} from 'App/data-hooks/queryKeys';

export type StopAppointmentDetailsProps = {
  shipmentId: string;
  stopId: string;
  /**
   * Event handler for when the 'schedule" or "reschedule" button is clicked
   */
  onClick?: () => void;
};
/**
 * Appointment component for Dock Scheduling.
 */
const StopAppointmentDetails = ({shipmentId, stopId, onClick}: StopAppointmentDetailsProps): JSX.Element | null => {
  const queryClient = useQueryClient();

  const {fiDockScheduling} = useSwFlags();
  const {facility, isFacilityLoading} = useShipmentStopFacilityQuery({
    shipmentId,
    stopId
  });
  const {carrier, isCarrierAssignmentLoading} = useCarrierAssignment(shipmentId);

  const {isDeleting, cancelAppointment} = useCancelAppointmentMutation();
  const {data, isAppointmentLoading} = useShipmentsStopAppointmentQuery({
    shipmentId,
    stopId
  });

  const {dock, appointment, isFirstComeFirstServe} = data;

  const {facilitiesShipmentMatchingData, facilitiesShipmentMatchingQuery} = useFacilitiesShipmentMatching({
    facilityId: facility?.id || '',
    shipmentId,
    stopId
  });

  const userMe = useUserMe();

  const isCarrierUser =
    Boolean(appointment?.carrierTenantId) && userMe.data?.company?.id === appointment?.carrierTenantId;

  const hasCarrierAssignment = isCarrierUser || Boolean(carrier?.id);

  // shows this component if the user has fiDockScheduling flag OR
  // is the carrier on the current appointment
  const showStopAppointmentDetails = fiDockScheduling || isCarrierUser;

  const onScheduleClick = () => {
    if (isNil(onClick)) {
      return;
    }

    onClick();
  };

  const onCancelClick = () => {
    if (isNil(appointment) || isNil(appointment.id)) {
      return;
    }
    cancelAppointment(appointment.id);
  };

  const isLoading = Boolean(isFacilityLoading || isAppointmentLoading || isDeleting || isCarrierAssignmentLoading);
  const isScheduleButtonDisabled =
    !hasCarrierAssignment || !facilitiesShipmentMatchingData?.matched_load_type_ids.length;
  const scheduleButtonTitle = 'Assign a carrier to schedule an appointment';

  if (isLoading || isNil(stop) || isNil(facility)) {
    return null;
  }
  const isUnscheduled = appointment?.status === AppointmentStatusEnum.Unscheduled;

  const appointmentStartTime =
    appointment?.start && !isUnscheduled
      ? formatParts(new Date(appointment?.start?.timestamp), appointment?.start?.timezone)
      : undefined;

  const appointmentEndTime =
    appointment?.end && !isUnscheduled
      ? formatParts(new Date(appointment?.end?.timestamp), appointment?.end?.timezone)
      : undefined;

  return showStopAppointmentDetails ? (
    <div className="w-full rounded bg-sw-background">
      <div className="grid grid-cols-2 p-4">
        <div className="justify-start text-sm font-bold">Appointment Information</div>
        <div className="flex flex-row gap-x-2 justify-self-end">
          {!isFirstComeFirstServe ? (
            <AppointmentButtonGroup
              title={scheduleButtonTitle}
              disabled={isScheduleButtonDisabled}
              isLoading={facilitiesShipmentMatchingQuery.isInitialLoading}
              status={appointment?.status || AppointmentStatusEnum.Unscheduled} // need to say there is no appointment if this is null
              onCancelClick={onCancelClick}
              onRescheduleClick={onScheduleClick}
              onScheduleClick={onScheduleClick}
            />
          ) : (
            <RescheduleFCFS
              refetch={() => queryClient.invalidateQueries([SHIPMENT_STOPS_APPOINTMENT_QUERY_KEY])}
              showAsButton
              appointment={appointment}
            />
          )}
        </div>
      </div>
      <div className="xs:w-full px-4 pb-4 sm:w-full md:w-4/5 lg:w-[90%]">
        <AppointmentDetailsPaper
          startTime={`${appointmentStartTime?.time ?? '--'} ${appointmentStartTime?.timezone?.abbreviated ?? ''}`}
          endTime={`${appointmentEndTime?.time ?? '--'} ${appointmentEndTime?.timezone?.abbreviated ?? ''}`}
          deliveryType={
            appointment?.deliveryType === 'SHIPPING'
              ? 'Shipping'
              : appointment?.deliveryType === 'RECEIVING'
              ? 'Receiving'
              : null
          }
          dockName={appointment ? dock?.name : null}
          isFirstComeFirstServe={isFirstComeFirstServe}
          appointmentDate={
            appointment?.start?.timestamp && !isUnscheduled ? formatDate(appointment?.start?.timestamp) : '--'
          }
        />
      </div>
    </div>
  ) : null;
};

export default StopAppointmentDetails;
