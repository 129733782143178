import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import {ReactNode} from 'react';

export const HandlingUnitsFeatureFlag = ({children}: {children: ReactNode}) => {
  const {ciHandlingUnitBuilder} = useSwFlags();
  if (!ciHandlingUnitBuilder) {
    return null;
  }
  return <>{children}</>;
};
