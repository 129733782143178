import {JSX, useEffect, useState} from 'react';
import {Modal} from '@shipwell/shipwell-ui';
import {LoadType} from '@shipwell/tempus-sdk';
import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import {AppointmentForm, SearchShipmentForm} from 'App/containers/appointments/components/forms';
import {useFacilityQuery} from 'App/data-hooks';

export interface ShipmentAppointmentInformation {
  /**
   * Shipment ID to book appointments for. This helps filter down
   * the appropriate facilities, docks, and availability.
   */
  shipmentId?: string | null;
  /**
   * Select ID of the stop to book the appointment for. If this is provided
   * the screen is transitioned first to the select appointment time on the
   * modal.
   */
  stopId?: string | null;
}

export interface AppointmentCreationModalProps extends ShipmentAppointmentInformation {
  showModal?: boolean;
  onClose: () => void;
  clickedTime?: Date | null;
  loadTypes: LoadType[];
  facilityId?: string;
}
/**
 * Handles workflows for searching for an appointment by shipment reference, bill of lading, pro number, or pro number.
 *
 * If no shipment id is provided as part of the props then the search form is loaded first to find a shipment.
 * if a shipment id is provided but no stop id then the appointment form will transition to the stop selection screen first.
 * If the shipment id and the stop id are provided the the appointment form will transition directly to the appointment screen
 * to select a time.
 */
const AppointmentCreationModal = ({
  onClose,
  shipmentId,
  showModal,
  clickedTime,
  stopId,
  loadTypes,
  facilityId
}: AppointmentCreationModalProps): JSX.Element => {
  const {fiDockScheduling} = useSwFlags();
  const [selectedShipmentId, setSelectedShipmentId] = useState<string | null | undefined>(shipmentId);

  // this component used to be gated by withConditionalFallback and fiDockScheduling
  // but carriers (generally !fiDockScheduling) also need to view AppointmentForm
  // only SearchShipmentForm needs to be gated by the feature flag
  // safe to show this modal wihtout the falg IF !!selectedShipmentId (show AppointmentForm)
  const canShowModal = !!selectedShipmentId || fiDockScheduling;

  const facilityQuery = useFacilityQuery({facilityId});

  useEffect(() => {
    setSelectedShipmentId(shipmentId);
  }, [shipmentId]);

  const handleClose = () => {
    if (!shipmentId) {
      setSelectedShipmentId(null);
    }
    onClose?.();
  };

  return (
    <Modal
      size="large"
      title="Schedule Appointments"
      show={canShowModal && showModal}
      bodyClassName={'p-0'}
      onClose={handleClose}
      footerComponent={null}
    >
      {!selectedShipmentId ? (
        <SearchShipmentForm
          onScheduleClick={(shipmentId) => setSelectedShipmentId(shipmentId)}
          onCancelClick={handleClose}
          loadTypes={loadTypes}
          facility={facilityQuery.facility}
          clickedTime={clickedTime}
        />
      ) : null}
      {selectedShipmentId ? (
        <div className="max-h-screen-75">
          <AppointmentForm
            onCancelClick={handleClose}
            shipmentId={selectedShipmentId}
            stopId={stopId}
            clickedTime={clickedTime}
          />
        </div>
      ) : null}
    </Modal>
  );
};

export default AppointmentCreationModal;
