import {useMutation} from '@tanstack/react-query';
import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import {OrderStop} from '@shipwell/corrogo-sdk';
import {updatePurchaseOrderShipTo} from 'App/api/corrogo/typed';

export const useUpdatePurchaseOrderShipTo = () => {
  const {decimalSupportForShipmentLineItems}: {decimalSupportForShipmentLineItems: boolean} = useSwFlags();
  const {mutate, mutateAsync, isLoading} = useMutation((params: {orderId: string; orderStop: OrderStop}) =>
    updatePurchaseOrderShipTo(params, decimalSupportForShipmentLineItems)
  );
  return {
    updatePurchaseOrderShipTo: mutate,
    updatePurchaseOrderShipToAsync: mutateAsync,
    isUpdatingPurchaseOrderShipTo: isLoading
  };
};
