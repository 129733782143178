import {useMemo, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import {isEmpty} from 'lodash';
import {
  getPrivateStandaloneTruckloadHistoricalPriceEstimate,
  getPublicStandaloneTruckloadHistoricalPriceEstimate,
  getTruckloadHistoricalPriceEstimate,
  getCustomerDatHistoricalRates
} from 'App/api/pricing';
import {
  DEFAULT_DROPOFF_RADIUS,
  DEFAULT_PICKUP_RADIUS,
  pricingIntelVariantsBySource,
  PRICING_INTEL_DATA_STATE_EMPTY,
  PRICING_INTEL_DATA_STATE_LOADING,
  PRICING_INTEL_DATA_STATE_MISSING_DATA,
  PRICING_INTEL_DATA_STATE_VALID,
  DAT_RATE_TYPE_SPOT,
  DAT_RATE_TYPE_CONTRACT
} from 'App/components/pricingIntelChart/pricingIntelConstants';
import {getFirstRangeDate, isEmptyPricingData} from 'App/components/pricingIntelChart/utils';
import {
  HISTORICAL_PRICING_MARKET_COMPOSITE,
  HISTORICAL_PRICING_INTERNAL,
  HISTORICAL_PRICING_PRIVATE,
  HISTORICAL_DAT_SPOT,
  HISTORICAL_DAT_CONTRACT
} from 'App/data-hooks/queryKeys';

const useHistoricalPricing = ({
  equipmentTypeMachineReadable,
  stringifiedStops,
  threeDigitPostalStringifiedStops,
  pickupRadius,
  dropOffRadius,
  selectedFormRange,
  pageSource,
  isDatIntegrated
}) => {
  const internalPricingQueryOptions = useMemo(
    () => ({
      pickupDateGte: getFirstRangeDate(selectedFormRange),
      //get all the matching rows
      limit: 1000
    }),
    [selectedFormRange]
  );
  const pricingIntelVariant = pricingIntelVariantsBySource[pageSource];
  const {automaticPricingIntel} = useSwFlags() || {};
  const flaggedInitialDataState =
    //show pricing by default if automatic flag enabled
    automaticPricingIntel ? PRICING_INTEL_DATA_STATE_EMPTY : pricingIntelVariant.initialDataState;
  const [availableDataSources, setAvailableDataSources] = useState([]);
  const [selectedDataSources, setSelectedDataSources] = useState([]);

  const queryOptions = {
    enabled: false,
    onError: (error) => {
      console.error(error);
    },
    cacheTime: 0,
    retry: false
  };

  const marketCompositeQuery = useQuery(
    [HISTORICAL_PRICING_MARKET_COMPOSITE],
    async () => {
      const {body} = await getPublicStandaloneTruckloadHistoricalPriceEstimate(
        equipmentTypeMachineReadable,
        stringifiedStops,
        {}
      );
      return body.data;
    },
    queryOptions
  );

  const internalDataQuery = useQuery(
    [HISTORICAL_PRICING_INTERNAL],
    async () => {
      const {body} = await getTruckloadHistoricalPriceEstimate(
        equipmentTypeMachineReadable,
        stringifiedStops,
        pickupRadius || DEFAULT_PICKUP_RADIUS,
        dropOffRadius || DEFAULT_DROPOFF_RADIUS,
        internalPricingQueryOptions
      );
      return body.data;
    },
    queryOptions
  );

  const privateDataSourcesQuery = useQuery(
    [HISTORICAL_PRICING_PRIVATE],
    async () => {
      const {body} = await getPrivateStandaloneTruckloadHistoricalPriceEstimate(
        equipmentTypeMachineReadable,
        stringifiedStops,
        {}
      );
      if (body.data) {
        return body.data;
      }
    },
    queryOptions
  );

  const datHistoricalRatesQuery = useQuery(
    [HISTORICAL_DAT_SPOT],
    async () => {
      const {body} = await getCustomerDatHistoricalRates(
        equipmentTypeMachineReadable,
        DAT_RATE_TYPE_SPOT,
        threeDigitPostalStringifiedStops
      );
      if (body) {
        return body.data;
      }
    },
    queryOptions
  );

  const datHistoricalRatesContractQuery = useQuery(
    [HISTORICAL_DAT_CONTRACT],
    async () => {
      const {body} = await getCustomerDatHistoricalRates(
        equipmentTypeMachineReadable,
        DAT_RATE_TYPE_CONTRACT,
        threeDigitPostalStringifiedStops
      );
      if (body) {
        return body.data;
      }
    },
    queryOptions
  );

  const historicalQueries = [
    marketCompositeQuery,
    internalDataQuery,
    ...(isDatIntegrated ? [datHistoricalRatesQuery] : [])
  ];

  const isFetchingQueries = historicalQueries.some((query) => query.isInitialLoading || query.isFetching);
  const isFetched = historicalQueries.every((query) => query.isFetched);
  const hasData = isFetched && historicalQueries.some((query) => query.data);

  const historicalQueriesData = useMemo(
    () =>
      hasData
        ? {
            shipwell_internal: internalDataQuery.data || {},
            shipwell_market: marketCompositeQuery.data || {},
            chainalytics_spot: privateDataSourcesQuery.data?.chainalytics_spot || [],
            dat_spot: privateDataSourcesQuery.data?.dat_spot || [],
            ...(isDatIntegrated
              ? {
                  customerDatHistoricalSpot: datHistoricalRatesQuery?.data || {},
                  customerDatHistoricalContract: datHistoricalRatesContractQuery?.data || {}
                }
              : {})
          }
        : {},
    [
      datHistoricalRatesContractQuery?.data,
      datHistoricalRatesQuery?.data,
      hasData,
      internalDataQuery.data,
      isDatIntegrated,
      marketCompositeQuery.data,
      privateDataSourcesQuery.data?.chainalytics_spot,
      privateDataSourcesQuery.data?.dat_spot
    ]
  );

  const rawHistoricalPrices = useMemo(
    () =>
      hasData
        ? {
            shipwell_internal: internalDataQuery.data?.prices || [],
            shipwell_market: marketCompositeQuery.data?.prices || [],
            chainalytics_spot: privateDataSourcesQuery.data?.chainalytics_spot || [],
            dat_spot: privateDataSourcesQuery.data?.dat_spot || [],
            ...(isDatIntegrated
              ? {
                  customerDatHistoricalSpot: datHistoricalRatesQuery?.data?.rateResponse?.response?.rates || [],
                  customerDatHistoricalContract:
                    datHistoricalRatesContractQuery?.data?.rateResponse?.response?.rates || []
                }
              : {})
          }
        : {},
    [
      datHistoricalRatesContractQuery?.data?.rateResponse?.response?.rates,
      datHistoricalRatesQuery?.data?.rateResponse?.response?.rates,
      hasData,
      internalDataQuery.data?.prices,
      isDatIntegrated,
      marketCompositeQuery.data?.prices,
      privateDataSourcesQuery.data?.chainalytics_spot,
      privateDataSourcesQuery.data?.dat_spot
    ]
  );
  const aggregateData = useMemo(
    () =>
      hasData
        ? {
            shipwell_internal: internalDataQuery.data?.aggregates || {},
            shipwell_market: marketCompositeQuery.data?.aggregates || {},
            chainalytics_spot: !isEmpty(privateDataSourcesQuery.data?.aggregates)
              ? privateDataSourcesQuery.data?.aggregates.chainalytics_spot_aggregates
              : {},
            dat_spot: !isEmpty(privateDataSourcesQuery.data?.aggregates)
              ? privateDataSourcesQuery.data?.aggregates.dat_spot_aggregates
              : {}
          }
        : {},
    [
      hasData,
      internalDataQuery.data?.aggregates,
      marketCompositeQuery.data?.aggregates,
      privateDataSourcesQuery.data?.aggregates
    ]
  );

  const totalMiles = useMemo(
    () => (hasData ? privateDataSourcesQuery?.data?.trip_miles || 0 : 0),
    [hasData, privateDataSourcesQuery?.data?.trip_miles]
  );

  const getTruckloadHistoricalPricingEstimate = async () => {
    resetHistoricalPricingData();
    await Promise.all(historicalQueries.map((query) => query.remove()));
    await Promise.all(historicalQueries.map((query) => query.refetch()));
  };

  const resetHistoricalPricingData = () => {
    historicalQueries.map((query) => query.remove());
    setAvailableDataSources([]);
    setSelectedDataSources([]);
  };

  const historicalPricingDataState = isFetchingQueries
    ? PRICING_INTEL_DATA_STATE_LOADING
    : hasData &&
      !isEmpty(rawHistoricalPrices) &&
      !isEmpty(aggregateData) &&
      isEmptyPricingData(rawHistoricalPrices, aggregateData)
    ? PRICING_INTEL_DATA_STATE_MISSING_DATA
    : hasData
    ? PRICING_INTEL_DATA_STATE_VALID
    : flaggedInitialDataState;

  return {
    historicalPricingDataState,
    rawHistoricalPrices,
    aggregateData,
    totalMiles,
    getTruckloadHistoricalPricingEstimate,
    availableDataSources,
    setAvailableDataSources,
    selectedDataSources,
    setSelectedDataSources,
    resetHistoricalPricingData,
    isFetchingQueries,
    historicalQueriesData
  };
};
export default useHistoricalPricing;
