import {Link, RouterProps} from 'react-router';
import PageHeader from 'App/common/pageHeader';
import {ReRenderCounter} from 'App/components/devTools/reRenderCounter/reRenderCounter';
import {SvgIcon} from '@shipwell/shipwell-ui';

export function PlaygroundPage(props: RouterProps) {
  return (
    <>
      <PageHeader title="Playground" />

      <div className="flex gap-2 px-5 py-2">
        <Link to="/playground/formik">Formik</Link>
        <Link to="/playground/multiselect">Multiselect</Link>
      </div>

      <div className="flex items-center justify-center" style={{color: 'blue'}}>
        <SvgIcon name={`Num22Outlined`} />
        <SvgIcon name={`Num68Outlined`} />
        <SvgIcon name={`Num97Filled`} />
        <SvgIcon name={`Num46Filled`} />
      </div>

      <ReRenderCounter name="PlaygroundPage" />

      <div className="mt-5 px-5">{props.children}</div>
    </>
  );
}
