import {useDatadogRum} from 'react-datadog';
import {useUserMe} from 'App/data-hooks';
import {useEffect} from 'react';

export function UserUpdater() {
  const user = useUserMe();
  const {setUser} = useDatadogRum();

  useEffect(() => {
    if (user.data && setUser) {
      const userName = `${user.data?.user?.first_name || ''} ${user.data?.user?.last_name || ''}`;

      setUser({
        email: user.data?.user?.email,
        name: userName,
        id: user.data?.user?.id || '',
        tenant_id: user.data?.company?.id
      });
    }
  }, [user.data, setUser]);

  return null;
}
