import {useEffect, useState} from 'react';
import {getPersonPayload} from './helpers';
import {useUserMe} from 'App/data-hooks';
import {useRollbarPerson} from '@rollbar/react';
import {Payload} from 'rollbar';
export function PersonUpdater() {
  const [currentUser, setCurrentUser] = useState<Payload['person']>();
  useRollbarPerson(currentUser as never);

  const me = useUserMe();
  const user = me.data?.user;

  useEffect(() => {
    if (user) {
      const personPayload = getPersonPayload(user);
      setCurrentUser(personPayload);
    }
  }, [user]);

  return null;
}
