import {ReactNode} from 'react';
import Datadog from 'react-datadog';
import {beforeSend} from './ignoreRules';
import {getDataDogEnvConfig, isDataDogEnabledForEnvironment, isDataDogEnabledForCurrentHostname} from './helpers';
import {UserUpdater} from './userUpdater/userUpdater';

export const DataDogProvider = ({children}: {children: ReactNode}) => {
  const dataDogEnvConfig = getDataDogEnvConfig();

  if (!dataDogEnvConfig) {
    return children;
  }

  if (!isDataDogEnabledForEnvironment(dataDogEnvConfig)) {
    return children;
  }

  if (!isDataDogEnabledForCurrentHostname()) {
    return children;
  }

  return (
    <Datadog
      applicationId={dataDogEnvConfig.dataDogApplicationId}
      clientToken={dataDogEnvConfig.dataDogClientToken}
      version={process.env.VERSION}
      site={'datadoghq.com'}
      service={'frontend-web'}
      env={dataDogEnvConfig.dataDogEnv}
      sessionSampleRate={100}
      sessionReplaySampleRate={100}
      sessionReplayRecording
      trackUserInteractions
      trackResources
      trackLongTasks
      allowedTracingUrls={[dataDogEnvConfig.dataDogTraceOrigins]}
      traceSampleRate={100}
      beforeSend={(event, context) => beforeSend({event, context})}
    >
      <UserUpdater />
      {children}
    </Datadog>
  );
};
