import {useSwFlags} from 'App/utils/hooks/useSwFlags';
import {CurrencyOfRecordField} from './CurrencyOfRecordField';
import '../_financials.scss';

export const FinancialsCurrencyOfRecordOnly = () => {
  const {stmShipmentCurrencyOfRecord} = useSwFlags();
  if (!stmShipmentCurrencyOfRecord) return null;
  return (
    <div>
      <h2 className="border-bottom">Financials</h2>
      <div className="quote-flow__financials">
        <CurrencyOfRecordField />
      </div>
    </div>
  );
};
