import {useDatadogRum} from 'react-datadog';

export function DatadogTools() {
  const datadogRum = useDatadogRum();
  const globalContext = datadogRum.getGlobalContext();

  return (
    <div>
      <h3>Datadog RUM ({globalContext ? 'initialized' : 'not initialized'})</h3>
      <pre>{datadogRum.version}</pre>
      <pre>{JSON.stringify(datadogRum.getUser(), null, 2)}</pre>
    </div>
  );
}
