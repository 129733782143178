import {RumErrorEvent, RumEvent, RumEventDomainContext} from '@datadog/browser-rum';

export interface BeforeSendParams {
  event: RumEvent;
  context: RumEventDomainContext;
}

export function beforeSend(params: BeforeSendParams) {
  if (params.event.error) {
    const rumError = params.event as RumErrorEvent;
    if (isCefSharpError(rumError)) {
      return false;
    }
  }

  return true;
}

// Identify if this is an error related to CefSharp library
// https://trackjs.com/javascript-errors/object-not-found-matching-id-methodname-paramcount/
function isCefSharpError(rumError: RumErrorEvent) {
  const message = rumError.error.message;
  const objectNotFoundPattern = /Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/;
  return objectNotFoundPattern.test(message);
}
