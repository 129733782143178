import {SubmissionError, change} from 'redux-form';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import reject from 'lodash/reject';
import {getFormattedParcelProvider, mapNewQuoteFormServiceOptions} from 'App/api/quoting/utils/transformParcelPayload';
import {validation} from 'App/containers/quotes/create/utils/validation';
import {
  createShipment,
  editShipmentPromise,
  createRFQPromise,
  editCarrierAssignmentPromise,
  dispatchError
} from 'App/actions/shipments';
import {getCompanyDetailsPromise} from 'App/api/company';
import {createTender} from 'App/api/tenders';
import {getCarrierRelationshipsPromise} from 'App/api/carriers';
import {removeCommasAndDollarSign} from 'App/utils/globals';
import {getShipmentTemperatureUnit} from 'App/utils/getShipmentTemperatureUnit';
import store from 'App/routes/store';
import {getParcelPackageType} from 'App/utils/parcelConstants';
import {getFedExRegistrationAccounts} from 'App/api/registration';
import {PackagingTypes} from 'App/utils/packagingTypes';
import {ALERT_ERROR} from 'App/actions/types';
import {ShipmentModeEnum} from 'App/utils/globalsTyped';

/**
 * Clean data of empty strings
 * @param  {Object}  data          The data to be cleaned
 * @param  {Boolean} options.hard  Should to empty fields be removed
 * @return {Object}                Cleaned data
 */
export const cleanPayload = (data, options = {}) => {
  const stack = [data];
  const {oldValue = ['', 'null'], nextValue = null, hardDelete = false} = options;

  while (stack.length) {
    const obj = stack.pop();

    for (const item in obj) {
      if (obj[item] !== undefined) {
        if (new Set(oldValue).has(obj[item])) {
          if (hardDelete) {
            delete obj[item];
          } else {
            obj[item] = nextValue;
          }
        } else if (typeof obj[item] === 'object' && !Array.isArray(obj[item])) {
          stack.push(obj[item]);
        } else if (Array.isArray(obj[item])) {
          stack.push(...obj[item]);
        }
      }
    }
  }
  return data;
};

/**
 * Normalize Field Error from request
 * @param  {Object} errors Field errors
 * @return {Object}        Normalized errors
 */
export const normalizeFieldErrors = (errors = {}) => {
  const stack = [errors];

  while (stack.length) {
    const errors = stack.pop();

    for (const error in errors) {
      if (errors[error] !== undefined) {
        if (Array.isArray(errors[error]) && errors[error].length && typeof errors[error][0] !== 'string') {
          stack.push(...errors[error]);
        } else if (Array.isArray(errors[error]) && errors[error].length && typeof errors[error][0] === 'string') {
          errors[error] = errors[error][0];
        } else if (typeof errors[error] === 'object') {
          stack.push(errors[error]);
        }
      }
    }
  }
  return errors;
};

/**
 * Quote Form Submit
 * Creates a new shipment and attaches created RFQ
 * @param  {Object}  shipment     Quote form values
 * @param  {Boolean} isManualRate Should create instant or manual rate
 */
export const createQuote = (shipment, dispatch, props = {}) => {
  const {isLTL, isFTL, isParcel} = getShipmentModeFlags(shipment);
  const {mode, equipment_type: equipmentTypes, ...shipmentPayload} = cleanPayload(shipment);
  const fieldErrors = validation(shipment);
  const options = {};
  const {fedex_enabled, usps_enabled, ups_enabled} = store.getState().userCompany.feature_flags;

  if (!isFTL && !isLTL && shipmentPayload.stops[1]) {
    shipmentPayload.stops[1].planned_date = null;
  }

  //make sure load board isn't selected if only one mode and it's LTL
  if (isLTL && !isFTL) {
    shipmentPayload.pushToLoadboard = false;
  }
  if (isParcel) {
    shipmentPayload.pushToLoadboard = false;
  }

  setDrayageProperties(shipmentPayload);

  shipmentPayload.stops.forEach((stop) => {
    stop.planned_time_window_end = moment(stop.planned_time_window_end, 'HH:mm:ss').format('HH:mm:ss');
    stop.planned_time_window_start = moment(stop.planned_time_window_start, 'HH:mm:ss').format('HH:mm:ss');
    if (stop?.location?.point_of_contacts?.length) {
      stop.location.point_of_contacts = stop.location.point_of_contacts.filter((pointOfContact) => {
        return !!pointOfContact.first_name || !!pointOfContact.email || !!pointOfContact.phone_number; // if none of these values are provided then we can completely ignore this altogether since none of the other data means anything without it
      });
    }
  });

  // if no parcel provider was selected, get rates for all enabled providers
  const {parcel_provider} = shipmentPayload || {};
  let {fedex_specific_options, ups_specific_options, usps_specific_options, service_options} = shipmentPayload;
  if ((isParcel && isNil(parcel_provider)) || (isParcel && parcel_provider.length === 0)) {
    if (fedex_enabled) {
      fedex_specific_options = {};
    }
    if (ups_enabled) {
      ups_specific_options = {};
    }
    if (usps_enabled) {
      usps_specific_options = {};
    }
    // rate all Genesis parcel providers
    service_options = {};
  }
  if (isParcel && parcel_provider?.length !== 1) {
    shipmentPayload.fedex_specific_options = null;
    shipmentPayload.ups_specific_options = null;
    shipmentPayload.usps_specific_options = null;
  }
  // api expects array of ids, but tag payload can sometimes be tag objects depending on entry point for quote creation
  const tagIds = get(shipmentPayload, 'metadata.tags', []).map((tag) => tag.id ?? tag);
  const payload = Object.assign({}, shipmentPayload, {
    mode: Array.isArray(mode) ? mode : [mode],
    equipment_type: Array.isArray(equipmentTypes) ? equipmentTypes : [equipmentTypes],
    metadata: {
      tags: tagIds,
      open: shipmentPayload.pushToDash ? true : false,
      archived: false,
      max_buy_amount:
        shipmentPayload.metadata &&
        shipmentPayload.metadata.max_buy_amount &&
        parseFloat(removeCommasAndDollarSign(shipmentPayload.metadata.max_buy_amount)),
      max_buy_amount_currency: props.currency,
      buy_it_now_amount:
        shipmentPayload.metadata &&
        shipmentPayload.metadata.buy_it_now_amount &&
        parseFloat(removeCommasAndDollarSign(shipmentPayload.metadata.buy_it_now_amount)),
      buy_it_now_amount_currency: props.currency,
      load_board_enabled: shipmentPayload.pushToLoadboard,
      ...(isFTL
        ? {bypass_carrier_insurance_coverage: !!shipmentPayload?.metadata?.bypass_carrier_insurance_coverage}
        : {})
    },
    stops: shipmentPayload.stops.map((shipmentStop, i) => {
      const stop = {...shipmentStop, ordinal_index: i};
      const scheduleSelect = stop['schedule-select'];

      // @todo Clean this up
      if (isFTL || isLTL) {
        if (scheduleSelect === '1' || scheduleSelect === 1) {
          if (!stop.planned_time_window_start || stop.planned_time_window_start === 'null') {
            stop.planned_time_window_start = '8:00';
            stop.planned_time_window_end = stop.planned_time_window_start;
          } else {
            stop.planned_time_window_start = moment(stop.planned_time_window_start, 'HH:mm:ss', true).isValid()
              ? moment(stop.planned_time_window_start, 'HH:mm:ss').format('HH:mm:ss')
              : moment(stop.planned_time_window_start).isValid()
              ? moment(stop.planned_time_window_start).format('HH:mm:ss')
              : null;
            stop.planned_time_window_end = stop.planned_time_window_start;
          }
        } else if (scheduleSelect === '2' || scheduleSelect === 2) {
          if (!stop.planned_time_window_start || stop.planned_time_window_start === 'null') {
            stop.planned_time_window_start = '8:00';
          } else {
            stop.planned_time_window_start = moment(stop.planned_time_window_start, 'HH:mm:ss', true).isValid()
              ? moment(stop.planned_time_window_start, 'HH:mm:ss').format('HH:mm:ss')
              : moment(stop.planned_time_window_start).isValid()
              ? moment(stop.planned_time_window_start).format('HH:mm:ss')
              : null;
          }
          if (!stop.planned_time_window_end || stop.planned_time_window_end === 'null') {
            stop.planned_time_window_end = '18:00';
          } else {
            stop.planned_time_window_end = moment(stop.planned_time_window_end, 'HH:mm:ss', true).isValid()
              ? moment(stop.planned_time_window_end, 'HH:mm:ss').format('HH:mm:ss')
              : moment(stop.planned_time_window_end).isValid()
              ? moment(stop.planned_time_window_end).format('HH:mm:ss')
              : null;
          }
        } else if (scheduleSelect === '3' || scheduleSelect === 3) {
          if (!stop.planned_time_window_end || stop.planned_time_window_end === 'null') {
            stop.planned_time_window_end = '18:00';
          } else {
            stop.planned_time_window_end = moment(stop.planned_time_window_end, 'HH:mm:ss', true).isValid()
              ? moment(stop.planned_time_window_end, 'HH:mm:ss').format('HH:mm:ss')
              : moment(stop.planned_time_window_end).isValid()
              ? moment(stop.planned_time_window_end).format('HH:mm:ss')
              : null;
          }
          stop.planned_time_window_start = null;
        } else if (scheduleSelect === '4' || scheduleSelect === 4) {
          if (!stop.planned_time_window_start || stop.planned_time_window_start === 'null') {
            stop.planned_time_window_start = '8:00';
          } else {
            stop.planned_time_window_start = moment(stop.planned_time_window_start, 'HH:mm:ss', true).isValid()
              ? moment(stop.planned_time_window_start, 'HH:mm:ss').format('HH:mm:ss')
              : moment(stop.planned_time_window_start).isValid()
              ? moment(stop.planned_time_window_start).format('HH:mm:ss')
              : null;
          }
          stop.planned_time_window_end = null;
        }
      } else {
        if (!stop.planned_time_window_start || stop.planned_time_window_start === 'null') {
          stop.planned_time_window_start = '8:00';
        } else {
          stop.planned_time_window_start = moment(stop.planned_time_window_start, 'HH:mm:ss', true).isValid()
            ? moment(stop.planned_time_window_start, 'HH:mm:ss').format('HH:mm:ss')
            : moment(stop.planned_time_window_start).isValid()
            ? moment(stop.planned_time_window_start).format('HH:mm:ss')
            : null;
        }
        if (!stop.planned_time_window_end || stop.planned_time_window_end === 'null') {
          stop.planned_time_window_end = '18:00';
        } else {
          stop.planned_time_window_end = moment(stop.planned_time_window_end, 'HH:mm:ss', true).isValid()
            ? moment(stop.planned_time_window_end, 'HH:mm:ss').format('HH:mm:ss')
            : moment(stop.planned_time_window_end).isValid()
            ? moment(stop.planned_time_window_end).format('HH:mm:ss')
            : null;
        }
      }
      return stop;
    })
  });

  if (shipment.line_items.length) {
    shipment.line_items.forEach((item, i) => {
      if (isParcel) {
        shipmentPayload.line_items[i].package_type = null;
        if (shipmentPayload.line_items[i].provider_specific_packaging !== PackagingTypes.YourPackaging) {
          shipmentPayload.line_items[i].length = null;
          shipmentPayload.line_items[i].width = null;
          shipmentPayload.line_items[i].height = null;
        }
      }
      if (item.value_per_piece) {
        //get the user's preferred currency for each line item
        item.value_per_piece_currency = props.currency;
      }
    });
  }

  if (!payload.temperature_unit) {
    payload.temperature_unit = getShipmentTemperatureUnit(payload);
  }

  if (shipment.customer) {
    options.xCompanyId = shipment.customer.value;
  }

  /** Field level validation */
  if (Object.keys(fieldErrors).length) {
    throw new SubmissionError(fieldErrors);
  }

  /** save shipment */
  return saveShipmentAndQuote({
    dispatch,
    payload,
    equipmentTypes, // nened to pass equipment_type since payload.equipment_type can only be a singular object for creating a shipment. Shipment schema currently only supports 1 equipment type
    config: {
      fedex_specific_options,
      usps_specific_options,
      ups_specific_options,
      service_options
    },
    options,
    props
  })
    .then((resp) => resp)
    .catch((error) => {
      const errors = normalizeFieldErrors(error.err.field_errors);
      errors._error = error.error_description;
      if (errors._error) {
        //show the overall error to the user
        dispatch(dispatchError(errors._error));
      }
      throw new SubmissionError(errors);
    });
};

/**
 * Return a boolean value if the rating process should be skipped for a given shipment form data
 * @param {Object} shipmentData
 */
export const shouldSkipRating = (shipmentData) => {
  const {isParcel} = getShipmentModeFlags(shipmentData);

  return (
    isParcel &&
    shipmentData?.parcel_provider?.length === 1 &&
    ['COLLECT', 'THIRD_PARTY'].some((el) => {
      return (
        el === shipmentData?.ups_specific_options?.bill_to_payment_type ||
        el === shipmentData?.fedex_specific_options?.bill_to_payment_type ||
        el === shipmentData?.usps_specific_options?.bill_to_payment_type
      );
    })
  );
};

/**
 * Handles redirects for successful Shipment and Quote creation
 * @param  {Object}   result   Quote response
 * @param  {Function} dispatch Redux dispatch func
 * @param  {Object}   props
 */
export const onCreateQuoteSuccess = (result, dispatch, props) => {
  const {shipment: shipmentId, shouldSkipRatingPhase} = result || {};

  if (shouldSkipRatingPhase) {
    return props.router.push(`/shipments/${shipmentId}`);
  }

  const requestTenders = props.values.isTender;
  const pushToRoutingGuide = props.values.pushToRoutingGuide;
  //when pushed to loadboard, we display a message when you land on the details page
  const pushedToLoadboard = props.values.pushToLoadboard ? '?loadboard=true' : '';
  //when this didnt go to a loadboard, we want to prompt user to request rates immediately on bids page
  const requestRates = props.values.pushToLoadboard ? '' : '?requestRates=true';
  //when the user wants to request tenders or push to routing guide, we want to send them to the modal prior to redirecting
  if (requestTenders || pushToRoutingGuide) {
    return;
  }
  if (shipmentId && props.values.isManual) {
    props.router.push(`/marketplace/${shipmentId}/manual-rate`);
  } else if (shipmentId && props.values.pushToDash) {
    props.router.push(`/shipments/${shipmentId}${pushedToLoadboard}`);
  } else if (shipmentId && result.autoquote && result.shipment_modes[0].code === 'PARCEL') {
    const {fedex_specific_options, ups_specific_options, usps_specific_options, rateRequestResponseId} = result || {};
    // no accounts were found so push to shipment details
    if (![fedex_specific_options, ups_specific_options, usps_specific_options, rateRequestResponseId].some(Boolean)) {
      props.router.push(`/shipments/${shipmentId}`);
    } else {
      props.router.push(`/marketplace/${shipmentId}/parcel-rates`);
    }
  } else if (shipmentId && result.autoquote && result.shipment_modes.find((e) => e.id === 2 || e.id === 4)) {
    //if autoquoting and either LTL or VLTL selected, go to instant rates (just FTL goes to bids)
    props.router.push(`/marketplace/${shipmentId}/instant-rates`);
  } else {
    props.router.push(`/marketplace/${shipmentId}/bids${requestRates}`);
  }
};

export const normalizeShipmentForQuote = (payload, isNewQuote, options = {}) => {
  const result = {...cleanPayload(payload, {oldValue: ['', null, 'null', NaN], newValue: ''})};
  const {parcelRateRequest} = options;
  const {capacity_providers: genesisParcelProviders, bill_to: genesisBillTo} = parcelRateRequest || {};

  if (isNewQuote) {
    delete result.id;
    // need to clear this on clone or backend throws an error
    delete result.capacity_provider_options;
  }

  //if not cloning use the RFQ mode/equipment
  if (!isNewQuote && payload.rfqs?.length > 0) {
    const {userCompany} = store.getState();
    const isParcelShipment = result.mode?.code === ShipmentModeEnum.PARCEL;

    const mostRecentRFQ =
      reject(
        payload.rfqs || [],
        (rfq) =>
          //there is a feature that creates FTL RFQs without equipment_type, filter them out
          rfq?.shipment_modes?.some((mode) => mode.id === 1) && !rfq?.equipment_types?.length
      )
        // for parcel we want to make sure RFQ data comes from the RFQ that belongs to the viewing company as parcel does not use rateshare
        .filter(
          (rfq) => !isParcelShipment || (!rfq.has_parent_rfq && rfq.company_owner_id === userCompany?.company?.id)
        )
        .sort((a, b) => a?.updated_at > b?.updated_at)[0] || {};

    result.mode = mostRecentRFQ.shipment_modes || [result.mode].filter(Boolean);
    result.equipment_type = mostRecentRFQ.equipment_types || [result.equipment_type].filter(Boolean);
    result.autoquote = mostRecentRFQ.autoquote || true;

    // parcel specific logic
    if (result.mode.some((mode) => mode.id === 6)) {
      const {
        fedex_specific_options: fedexSpecificOptions,
        ups_specific_options: upsSpecificOptions,
        usps_specific_options: uspsSpecificOptions
      } = mostRecentRFQ;
      // sets the parcel_provider initial field values
      result.parcel_provider = [
        ...(fedexSpecificOptions ? [{id: 'fedex', name: 'FedEx'}] : []),
        ...(upsSpecificOptions ? [{id: 'ups', name: 'UPS'}] : []),
        ...(uspsSpecificOptions ? [{id: 'usps', name: 'USPS'}] : []),
        ...(genesisParcelProviders?.length
          ? genesisParcelProviders.map((provider) => ({
              id: provider.provider_code,
              name: getFormattedParcelProvider(provider.provider_code)
            }))
          : [])
      ];
      result.fedex_specific_options = fedexSpecificOptions;
      result.ups_specific_options = upsSpecificOptions;
      result.usps_specific_options = uspsSpecificOptions;
      if (genesisParcelProviders?.length) {
        if (genesisParcelProviders.length === 1) {
          const {service_codes, ...restProvider} = genesisParcelProviders[0];
          result.service_options = {...restProvider, service_code: service_codes?.[0], bill_to: genesisBillTo};
        } else {
          result.service_options = {providerCodes: genesisParcelProviders.map((provider) => provider.provider_code)};
        }
      }
    }
    // otherwise use the shipment mode/equipment
  } else {
    result.mode = payload?.mode ? [payload.mode] : [];
    result.equipment_type = payload?.equipment_type ? [payload.equipment_type] : [];
  }

  const {isParcel} = getShipmentModeFlags(result);

  result.line_items = payload.line_items
    ? payload.line_items.map((lineItem) =>
        Object.assign({}, lineItem, {
          hazmat: Boolean(lineItem.hazmat_identification_number),
          hazmatDetails: {
            hazmat_identification_number: lineItem.hazmat_identification_number,
            hazmat_hazard_class: lineItem.hazmat_hazard_class,
            hazmat_packing_group: lineItem.hazmat_packing_group,
            hazmat_proper_shipping_name: lineItem.hazmat_proper_shipping_name
          },
          //if parcel_provider only has single provider we can use whatever is on the shipment currently
          //otherwise we can default to YOUR_PACKAGING, which is the only option when quoting multiple parcel providers
          ...(!isNewQuote && isParcel
            ? {
                provider_specific_packaging:
                  result?.parcel_provider?.length === 1
                    ? lineItem.provider_specific_packaging
                    : PackagingTypes.YourPackaging
              }
            : {})
        })
      )
    : [];

  result.stops = payload.stops
    ? payload.stops.map((stop) => {
        if (stop.location && !stop.location.location_type) {
          stop.location.location_type = {id: 1, name: 'Business (with dock or forklift)'};
        }
        return stop;
      })
    : [];

  result.customer = payload.customer ? {label: payload.customer.name, value: payload.customer.id} : '';

  return cleanPayload(result, {oldValue: ['', 'null', NaN], nextValue: ''});
};

const defaultSaveShipmentAndQuoteOptions = {
  fedex_specific_options: null, // must be null or actual valid value, else backend throws error
  usps_specific_options: null, // must be null or actual valid value, else backend throws error
  ups_specific_options: null // must be null or actual valid value, else backend throws error
};

/**
 * Has the side-effect of setting the fields on the shipment
 * related to drayage and normalizing date information.
 */
const setDrayageProperties = (shipmentPayload) => {
  if (shipmentPayload.drayage_estimated_arrival_date) {
    shipmentPayload.drayage_estimated_arrival_date = moment(shipmentPayload.drayage_estimated_arrival_date).isValid()
      ? moment.utc(shipmentPayload.drayage_estimated_arrival_date).format('YYYY-MM-DD')
      : null;
  }
  if (shipmentPayload.drayage_release_date) {
    shipmentPayload.drayage_release_date = moment(shipmentPayload.drayage_release_date).isValid()
      ? moment.utc(shipmentPayload.drayage_release_date).format('YYYY-MM-DD')
      : null;
  }
  if (shipmentPayload.drayage_last_free_date) {
    shipmentPayload.drayage_last_free_date = moment(shipmentPayload.drayage_last_free_date).isValid()
      ? moment.utc(shipmentPayload.drayage_last_free_date).format('YYYY-MM-DD')
      : null;
  }
  if (shipmentPayload.drayage_container_return_date) {
    shipmentPayload.drayage_container_return_date = moment(shipmentPayload.drayage_container_return_date).isValid()
      ? moment.utc(shipmentPayload.drayage_container_return_date).format('YYYY-MM-DD')
      : null;
  }
};

/**
 * Gets all the modes of the shipment are eligble for creating quotes for.
 */
export const getShipmentModeFlags = ({mode}) => {
  const result = {isLTL: false, isFTL: false, isDrayage: false, isParcel: false, isIntermodal: false};
  const modes = Array.isArray(mode) ? mode : [mode];
  for (let i = 0; i < modes.length; ++i) {
    const id = getShipmentModeId(modes[i]);
    switch (id) {
      case 1:
        result.isFTL = true;
        break;
      case 2:
      case 4:
        result.isLTL = true;
        break;
      case 5:
        result.isDrayage = true;
        break;
      case 6:
        result.isParcel = true;
        break;
      case 7:
        result.isIntermodal = true;
        break;
      default:
        break;
    }
  }

  return result;
};

const saveShipmentAndQuote = async ({
  dispatch,
  payload,
  equipmentTypes,
  config = defaultSaveShipmentAndQuoteOptions,
  options = {},
  props = {}
}) => {
  const shouldSkipRatingPhase = shouldSkipRating(payload);
  const shipmentPayload = {
    ...payload,
    // shipments cannot have more than one mode but quotes can.
    mode: Array.isArray(payload.mode) && payload.mode.length === 1 ? payload.mode[0] : null,
    // shipments can only have one equipment type.
    equipment_type:
      Array.isArray(payload.equipment_type) && payload.equipment_type.length === 1 ? payload.equipment_type[0] : null
  };
  const {userCompany} = store.getState();
  const {fedex_enabled: fedExEnabled} = userCompany.feature_flags;
  const shouldSaveFinancials = Boolean(shipmentPayload.financials?.length);
  const isEdit = !isNil(shipmentPayload.id);
  const saveShipmentRequest = isEdit
    ? editShipmentPromise(shipmentPayload.id, shipmentPayload, options)
    : dispatch(createShipment(shipmentPayload, options));

  const response = await saveShipmentRequest;
  const shipmentData = response.id ? response : response.details?.id ? response.details : null;
  if (!shipmentData?.id) {
    return Promise.reject(response); // failed to create a shipment
  }
  // set the shipment id in the form values so if the user has to resubmit this function will PUT instead of POST
  if (!isEdit && props.form) {
    dispatch(change(props.form, 'id', shipmentData.id));
  }
  const {isParcel, isLTL, isFTL, isDrayage} = getShipmentModeFlags(payload);
  const shouldAutoquote = !isFTL && !isDrayage; // don't enable for FTL or dryage or

  let shouldCreateOwnRFQ = false;

  const isThirdPartyShipment =
    !!shipmentData.customer?.id && shipmentData.customer.id !== store.getState().auth?.company?.id;
  //when creating on behalf of a customer and pushing to load board, we want to immediately create an RFQ with the details for this company too
  if (isThirdPartyShipment && payload.pushToLoadboard) {
    shouldCreateOwnRFQ = true;
  }

  const rfqPayload = {
    shipment: shipmentData.id,
    parent_rfq: null,
    equipment_types: equipmentTypes || [],
    shipment_modes: Array.isArray(payload.mode) ? payload.mode : [payload.mode] || [],
    autoquote: shouldAutoquote
  };

  // TITAN-4803: passing the fedex freight account to a third party RFQ causes a 400
  if (isLTL && fedExEnabled && !isThirdPartyShipment) {
    const fedExAccountResp = await getFedExRegistrationAccounts(options);
    if (fedExAccountResp.ok) {
      const accounts = fedExAccountResp.body?.results || [];
      for (let i = accounts.length - 1; i >= 0; --i) {
        const account = accounts[i];
        if (account?.is_freight && account?.meter_number != null) {
          config.fedex_specific_options = {
            account: account.id,
            packaging: PackagingTypes.YourPackaging // needs to be set for LTL
          };
          break; // Exit the loop once the condition is met
        }
      }
    }
  }

  if (shipmentData && shouldSaveFinancials) {
    //we need to do a put on the response to add the customer financials immediately
    const customerFinancials = payload.financials;
    const relationshipToModify = shipmentData.customer.id === store.getState().auth.company.id ? 'CUSTOMER' : 'VENDOR';
    let updatePayload;
    //when you created this for a customer, you update the vendor financials
    if (relationshipToModify === 'CUSTOMER') {
      updatePayload = Object.assign({}, shipmentData.relationship_to_customer, {
        vendor_charge_line_items: customerFinancials
      });
    } else {
      updatePayload = Object.assign({}, shipmentData.relationship_to_vendor, {
        vendor_charge_line_items: customerFinancials
      });
    }
    const vendorAssignResponse = await editCarrierAssignmentPromise(
      shipmentData.id,
      relationshipToModify === 'CUSTOMER'
        ? shipmentData.relationship_to_customer.id
        : shipmentData.relationship_to_vendor.id,
      updatePayload
    );
    if (vendorAssignResponse) {
      /** create new RFQ with shipment id */

      const rfqResponse = await createRFQPromise(rfqPayload, options);
      if (rfqResponse && shouldCreateOwnRFQ) {
        const ownRfqResponse = await createRFQPromise(rfqPayload, {}); // not sure why there is an empty options passed?
        return ownRfqResponse;
      }
      return rfqResponse;
    }
  }

  let packageType = null;
  if (isParcel && payload?.line_items) {
    const parcelPackageType = getParcelPackageType(payload.line_items);
    if (parcelPackageType === 'MIXED_TYPES') {
      // mixed types is UI only, need to send backend-specific string
      packageType = PackagingTypes.YourPackaging;
    } else {
      packageType = parcelPackageType;
    }
  }

  const rfqPayloadWithOptions = {
    ...rfqPayload,
    fedex_specific_options: config.fedex_specific_options || null,
    ups_specific_options: config.ups_specific_options
      ? {
          ...config.ups_specific_options,
          packaging: packageType
        }
      : null,
    usps_specific_options: config.usps_specific_options
      ? {
          ...config.usps_specific_options,
          packaging: packageType
        }
      : null
  };

  const {parcelCapacityProviderOptions, billTo} =
    config.service_options && !isEmpty(config.service_options)
      ? mapNewQuoteFormServiceOptions({...config.service_options}, payload.preferred_currency)
      : {};

  if (!shouldSkipRatingPhase) {
    // create new RFQ with shipment id
    // also create a Genesis Parcel Request
    try {
      // TITAN-5055: in order to rate sub-accounts we need to stop passing the xCompanyId for parcel shipments
      const {xCompanyId, ...parcelRfqOptions} = options;
      // choosing Promise.all to fail the entire process if either promise fails
      const [rfqResponse, rateRequestResponse] = await Promise.all([
        createRFQPromise(rfqPayloadWithOptions, isParcel ? parcelRfqOptions : options),
        // if config.service_options is null or undefined do not get genesis rates
        ...(config.service_options && props.handleCreateParcelRequest
          ? [
              props.handleCreateParcelRequest({
                shipment: shipmentData,
                parcelCapacityProviderOptions,
                billTo
              })
            ]
          : [])
      ]);
      if (rfqResponse && shouldCreateOwnRFQ) {
        const ownRfqResponse = await createRFQPromise(rfqPayloadWithOptions, {}); // not sure why there is an empty options passed?
        return {...ownRfqResponse, rateRequestResponseId: rateRequestResponse?.id};
      }
      return {...rfqResponse, rateRequestResponseId: rateRequestResponse?.id};
    } catch {
      // used to display the old style error toast
      dispatch({
        type: ALERT_ERROR,
        payload: 'Unable to request rates.'
      });
      // return this to reject the parent promise and force the Quote page to stay on the form
      return Promise.reject();
    }
  }

  // if skipping quoting phase, create a tender
  const parcelProviderToOptionsMap = {
    ups: 'ups_specific_options',
    usps: 'usps_specific_options',
    fedex: 'fedex_specific_options'
  };

  const parcelProviderName = payload?.parcel_provider?.[0]?.id;
  const parcelProviderOptionsKey = parcelProviderToOptionsMap[parcelProviderName];
  const unitName = payload?.[parcelProviderOptionsKey]?.bill_to_payment_type;
  const carriers = await getCarrierRelationshipsPromise({pageSize: 10000, q: parcelProviderName});
  const carrierCompanyId = carriers.body.results[0].carrier_direct_integration_profile.company;
  const companyData = (await getCompanyDetailsPromise(carrierCompanyId)).body;

  const carrierOptions = {
    ...payload?.[parcelProviderOptionsKey],
    packaging: payload?.line_items?.[0]?.provider_specific_packaging,
    carrier: parcelProviderName
  };

  await createTender({
    carrier_options: carrierOptions,
    equipment_type: null,
    mode: 6,
    expires_at: null,
    message: null,
    charge_line_items: [
      {
        unit_name: unitName,
        unit_amount: 0,
        unit_amount_currency: payload?.line_items?.[0]?.insured_value_currency || 'USD',
        unit_quantity: payload?.line_items?.[0]?.total_packages.toString() || '1',
        category: 'OTHER'
      }
    ],
    shipment: shipmentData.id,
    rate_type: 'FLAT_RATE',
    info_message: 'Carrier instructions for all shipments test.',
    tender_to_company: companyData.id,
    contract: null,
    involved_tender_to_company_users: []
  });

  return {shipment: shipmentData.id, shouldSkipRatingPhase};
};

/**
 * Gets a shipment mode regardless of object state or type and always returns
 * the id as a number.
 * @param {string|number|{id: number, code: string, description: string}} mode
 * @returns {number}
 */
const getShipmentModeId = (mode) => {
  // incase you're wondering, in javascript '2' === 2 is false.
  // depending on where the shipment is coming from it can have a different structure.
  // This function performs a "duck" typing and casts everything to a Number.
  if (mode === null || mode === undefined) {
    throw Error('Cannot determine mode id without mode parameter');
  }

  if (mode['id']) {
    return Number(mode.id);
  }

  return Number(mode);
};
